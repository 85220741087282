import React from 'react';
import WizardStep from '../wizard-step';
import BankDetails from '../bank/bank-details';

import { ProposalStepProps } from '../types/ProposalStepProps';

const BankDetailsStep: React.FC<ProposalStepProps> = ({ id, stepNumber, formData, onBack, onForward }) => {
    return (
        <WizardStep id={id} stepNumber={stepNumber} onBack={onBack} onForward={onForward}>
            <BankDetails formData={formData} />
        </WizardStep>
    );
}

export default BankDetailsStep;