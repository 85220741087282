import React, { useState, useEffect, useRef } from 'react';
import { useFormContext } from "react-hook-form";
import AddressInput from './address-input';
import DateInput from '../common/date';
import ErrorMessage from '../../layout/forms/error-message';

import { isBefore, differenceInMonths, startOfToday } from 'date-fns';
import { IsValidDate } from '../../../helpers/validation';
import { getFieldError, getFieldName } from '../../../helpers/fieldArrayHelper';
import { getDate } from '../../../helpers/dateTimeHelper';
import { getNumberOrNull } from '../../../helpers/stringHelper';

interface IProps {
    title: string;
    datesDisplayMode: number;
    addressData: any;

    collectionName: string | null;
    index: number;

    //events
    onTimePeriodUpdate: any | null;
}

//TODO: do we need useState for timeAtAddressYears\timeAtAddressMonths? use getValues instead?
const AddressHistoryItem: React.FC<IProps> = ({ title, datesDisplayMode, addressData, collectionName, index, onTimePeriodUpdate }) => {
    const { setValue, register, clearErrors, formState: { errors } } = useFormContext();

    const isInitialMount = useRef(true);

    const [fromDate, setFromDate] = useState(addressData?.fromDate ?? null);
    const [timeAtAddressYears, setTimeAtAddressYears] = useState<number>(null);
    const [timeAtAddressMonths, setTimeAtAddressMonths] = useState<number>(null);
    const dateError = getFieldError("fromDate", collectionName, index, errors);

    useEffect(() => {
        if (isInitialMount.current) {
            if (datesDisplayMode === 2) {
                setTimeAtAddressYears(addressData?.timeAtAddressYears);
                setTimeAtAddressMonths(addressData?.timeAtAddressMonths);
            }

            isInitialMount.current = false;
        } else {
            let totalMonths: number | null = null;

            //WARN: do not remove null checks
            if ((timeAtAddressYears !== null && timeAtAddressYears >= 0) || (timeAtAddressMonths !== null && timeAtAddressMonths >= 0)) {
                totalMonths = (timeAtAddressYears ?? 0) * 12 + (timeAtAddressMonths ?? 0);
            }

            setValue(getFieldName("timeAtAddressYears", collectionName, index), timeAtAddressYears);
            setValue(getFieldName("timeAtAddressMonths", collectionName, index), timeAtAddressMonths);
            setValue(getFieldName("totalMonthsAtAddress", collectionName, index), totalMonths);

            if (onTimePeriodUpdate) {
                onTimePeriodUpdate(totalMonths);
            }
        }
    }, [timeAtAddressYears, timeAtAddressMonths]);

    const handleTimePeriodChanges = (fieldName: string, value: string) => {
        switch (fieldName) {
            case "timeAtAddressYears":
                setTimeAtAddressYears(getNumberOrNull(value));
                break;

            case "timeAtAddressMonths":
                setTimeAtAddressMonths(getNumberOrNull(value));
                break;
        }
    }

    const onDateChange = (date) => {
        let formattedDate = date.toLocaleDateString("en-GB");

        if (datesDisplayMode === 1) {
            setValue(getFieldName("fromDate", collectionName, index), formattedDate);
            setFromDate(formattedDate);

            setTimeAtAddressYears(null);
            setTimeAtAddressMonths(null);

            if (IsValidDate(formattedDate)) {
                const dateObj = getDate(formattedDate);
                const currentDateObj = startOfToday();

                if (!isBefore(currentDateObj, dateObj)) {
                    const monthsPeriod = differenceInMonths(currentDateObj, dateObj);

                    const years: number = Math.floor(monthsPeriod / 12);
                    const months: number = monthsPeriod % 12;

                    setTimeAtAddressYears(years);
                    setTimeAtAddressMonths(months);
                }

                clearErrors(getFieldName("fromDate", collectionName, index));
            }
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-24">
                    {(datesDisplayMode === 1) && <h2 className="w-100 d-block h3 mb-0">Address Details</h2>}
                    <h3 className="w-100 d-block h4 mt-3 mt-md-4 mt-lg-5 mb-3">{title}</h3>
                </div>
            </div>
            <AddressInput formData={addressData} index={index} collectionName={collectionName} />
            <input type="hidden" {...register(getFieldName("totalMonthsAtAddress", collectionName, index), { /*required: true,*/ max: { value: 720, message: "Maximum 60 years allowed" } })} />
            

            {(datesDisplayMode === 1) &&
                <div className="row">
                    <div className="col-24 col-md-12 col-lg-8">
                        <label className="w-100 d-flex justify-content-start align-items-center mb-2">From Date <small className="d-inline-block text-danger ms-1">(Required)</small></label>
                        <div className={`w-100 d-flex flex-nowrap justify-content-start align-items-center ${(getFieldError("fromDate", collectionName, index, errors) || getFieldError("totalMonthsAtAddress", collectionName, index, errors)) ? " is-invalid" : ""}`}>
                            <input type="hidden" {...register(getFieldName("timeAtAddressYears", collectionName, index))} />
                            <input type="hidden" {...register(getFieldName("timeAtAddressMonths", collectionName, index))} />
                            <input type="hidden"
                                {...register(getFieldName("fromDate", collectionName, index),
                                    {
                                        required: true,
                                        validate: {
                                            isDate: v => IsValidDate(v)
                                        }
                                    }
                                )}
                                defaultValue={fromDate}
                            />
                            <DateInput selectedDate={fromDate} valid={((dateError?.type === 'required' || dateError?.type === 'isDate')) ? false : true} onDateChange={onDateChange} />
                        </div>
                        <ErrorMessage errors={errors} name={getFieldName("fromDate", collectionName, index)} message="Select a date" />
                        <ErrorMessage errors={errors} name={getFieldName("totalMonthsAtAddress", collectionName, index)} />
                        <p className="form-text mt-1 mb-0">{`${timeAtAddressYears ?? 0} Year${!timeAtAddressYears || timeAtAddressYears > 1 ? "s" : ""} ${timeAtAddressMonths ?? 0} Month${(!timeAtAddressMonths) || (timeAtAddressMonths > 1) ? "s" : ""}`}</p>
                    </div>
                </div>
            }

            {(datesDisplayMode === 2) &&
                <>
                    <div className="row">
                        <div className="col-24 col-md-12 col-lg-8">
                            <label className="w-100 d-flex justify-content-start align-items-center mb-2">Years &amp; months at address <small className="d-inline-block text-danger ms-1">(Required)</small></label>
                            <div className="row">
                                <div className="col-24 col-md-12 pe-md-2">
                                    <input type="number" placeholder="Years"
                                        {...register(getFieldName("timeAtAddressYears", collectionName, index), { required: true, pattern: /^[0-9]*$/, min: 0, max: 60 })}
                                        className={`form-control ${getFieldError("timeAtAddressYears", collectionName, index, errors) ? "is-invalid" : ""}`}
                                        onBlur={(evt) => { handleTimePeriodChanges("timeAtAddressYears", evt.target.value); }}
                                        onKeyPress={(evt) => { if (evt.keyCode === 13) { handleTimePeriodChanges("timeAtAddressYears", (evt.target as HTMLInputElement).value); evt.stopPropagation(); } }}
                                    />
                                    <ErrorMessage errors={errors} name={getFieldName("timeAtAddressYears", collectionName, index)} message="Years are required" />
                                </div>
                                <div className="col-24 col-md-12 ps-md-2">
                                    <input type="number" placeholder="Months"
                                        {...register(getFieldName("timeAtAddressMonths", collectionName, index), { required: true, pattern: /^[0-9]*$/, min: 0, max: 11 })}
                                        className={`form-control ${getFieldError("timeAtAddressMonths", collectionName, index, errors) ? "is-invalid" : ""}`}
                                        onBlur={(evt) => { handleTimePeriodChanges("timeAtAddressMonths", evt.target.value); }}
                                    />
                                    <ErrorMessage errors={errors} name={getFieldName("timeAtAddressMonths", collectionName, index)} message="Months are required" />
                                </div>
                                <div className="col-24">
                                    <ErrorMessage errors={errors} name={getFieldName("totalMonthsAtAddress", collectionName, index)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default AddressHistoryItem;