import React from 'react';
import { useFormContext } from "react-hook-form";
import ErrorMessage from '../../layout/forms/error-message';

import { EmailAddressIsValid } from '../../../helpers/validation';

interface IProps {
    defaultValue: any;

    readOnly: boolean;
}

const EmailAddress: React.FC<IProps> = ({ defaultValue, readOnly }) => {
    const { register, formState: { errors } } = useFormContext();

    return (
        <div className="w-100 d-flex flex-wrap">
            <label htmlFor="emailAddress" className="w-100 d-flex justify-content-start align-items-center mb-2">Email Address <small className="d-inline-block text-danger ms-1">(Required)</small></label>
            <input type="email" inputMode="email" placeholder="Your Email Address" readOnly={readOnly} maxLength={100} id="emailAddress" defaultValue={defaultValue}
                {...register("emailAddress",
                    {
                        required: "Enter an email address",
                        maxLength: 100,
                        validate: value => EmailAddressIsValid(value) === true || 'Valid email address required'
                    }
                )}
                className={`form-control ${errors.emailAddress ? "is-invalid" : ""}`}
            />
            <ErrorMessage errors={errors} name="emailAddress" />
        </div>
    );
}

export default EmailAddress;