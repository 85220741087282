import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styles from './header.module.scss';

import { ConfigContext } from '../../../App';

//TODO: check Link\a usage for the whole site
const Header: React.FC = () => {
    const config = useContext(ConfigContext);

    return (
        <header className={`${styles.header} container-fluid py-3 bg-gradient`}>
            <div className="container">
                <div className="row">
                    <div className="col-12 d-flex align-items-center justify-content-start">
                        <img src={process.env.REACT_APP_CDN + config.theme.images.logoPath} className="img-fluid" alt={config.defaults.companyName} />
                    </div>
                    {config.theme.poweredBy &&
                        <div className="col-12 d-flex align-items-center justify-content-end">
                            {config.theme.poweredBy.siteUrl ?
                                    <Link to={config.theme.poweredBy.siteUrl}><img src={process.env.REACT_APP_CDN + config.theme.poweredBy.logo} alt={config.theme.poweredBy.name} /></Link> :
                                    <img src={process.env.REACT_APP_CDN + config.theme.poweredBy.logo} alt={config.theme.poweredBy.name} />
                                }
                        </div>
                    }
                </div>
            </div>
        </header>
    );

    return null;
}

export default Header;