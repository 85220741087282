import React, { useState } from 'react';
import { useFormContext } from "react-hook-form";
import DateInput from './date';

import { IsOver18, IsValidDate } from '../../../helpers/validation';
import { getFieldError, getFieldName } from '../../../helpers/fieldArrayHelper';

interface IProps {
    collectionName: string;
    index: number;
    value: string;
    verifyAge?: boolean;
}

const DateOfBirth: React.FC<IProps> = ({ collectionName, index, value, verifyAge = false }) => {
    const { register, setValue, setError, clearErrors, formState: { errors } } = useFormContext();
    const [innerValue, setInnerValue] = useState<string>(value || '');
    const propertyName = "birthDate";
    const name = getFieldName(propertyName, collectionName, index);
    const error = getFieldError(propertyName, collectionName, index, errors);
    
    const onDateChange = (date) => {
        let selectedDate = date.toLocaleDateString("en-GB");
        
        setValue(name, selectedDate);
        setInnerValue(selectedDate);

        if(!IsValidDate(selectedDate)) {
            setError(name, { type: 'isDate' });
        } else if (verifyAge && !IsOver18(selectedDate)) {
            setError(name, { type: 'isOver18' });
        } else {
            clearErrors(name);
        }
    }

    return (
        <>
            <label className="w-100 d-flex justify-content-start align-items-center mb-2">
                Date of Birth <small className="d-inline-block text-danger ms-1">(Required)</small>
            </label>
            <div className={`w-100 d-flex flex-nowrap justify-content-start align-items-center ${error ? " is-invalid" : ""}`}>
                <input type="hidden"
                    {...register(name,
                        {
                            required: true,
                            validate: {
                                isDate: v => IsValidDate(v),
                                isOver18: v => !verifyAge || IsOver18(v)
                            }
                        }
                    )}
                    defaultValue={innerValue} 
                />
                <DateInput selectedDate={innerValue} valid={((error?.type === 'required' || error?.type === 'isDate') || (verifyAge && error?.type === 'isOver18')) ? false : true} onDateChange={onDateChange} />
            </div>
            {(error?.type === 'required' || error?.type === 'isDate') && <span className="invalid-feedback d-block">Select a date</span>}
            {(verifyAge && error?.type === 'isOver18') && <span className="invalid-feedback d-block">You need to be at least 18 years old</span>}
        </>
    );
}

export default DateOfBirth;