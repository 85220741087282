import React from 'react';
import { useFormContext } from "react-hook-form";
import ErrorMessage from '../../layout/forms/error-message';

import { getFieldError, getFieldId, getFieldName } from '../../../helpers/fieldArrayHelper';

interface IProps {
    formData: any;
    collectionName: string;
    index: number;
}

const FullName: React.FC<IProps> = ({ formData, collectionName, index }) => {
    const { register, formState: { errors } } = useFormContext();

    const fieldName = (name) => {
        return getFieldName(name, collectionName, index);
    }

    const fieldId = (name) => {
        return getFieldId(name, collectionName, index);
    }

    const fieldError = (name: string, errors: any) => {
        return getFieldError(name, collectionName, index, errors);
    }

    return (
        <>
            <div className="row">
                <div className="col-24 col-md-4 mb-3">
                    <div className="w-100 d-flex flex-wrap">
                        <label htmlFor={fieldId("title")} className="w-100 d-flex justify-content-start align-items-center mb-2">Title <small className="d-inline-block text-danger ms-1">(Required)</small></label>
                        <select id={fieldId("title")} defaultValue={formData.title}
                            {...register(fieldName("title"), { required: "Select your title" })}
                            className={`form-select ${fieldError("title", errors) ? "is-invalid" : ""}`}
                        >
                            <option value="">Select</option>
                            <option value="1">Mr</option>
                            <option value="2">Mrs</option>
                            <option value="3">Miss</option>
                            <option value="4">Ms</option>
                            <option value="5">Dr</option>
                            <option value="6">Rev</option>
                            <option value="7">Prof</option>
                            <option value="8">Lt. Col</option>
                            <option value="9">Lord</option>
                        </select>
                        <ErrorMessage errors={errors} name={fieldName("title")} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-24 col-md-8 mb-3">
                    <div className="w-100 d-flex flex-wrap">
                        <label htmlFor={fieldId("firstName")} className="w-100 d-flex justify-content-start align-items-center mb-2">First Name <small className="d-inline-block text-danger ms-1">(Required)</small></label>
                        <input type="text" placeholder="First Name" maxLength={100} id={fieldId("firstName")} defaultValue={formData.firstName}
                            {...register(fieldName("firstName"), { required: "Enter your first name", maxLength: 100 })}
                            className={`form-control ${fieldError("firstName", errors) ? "is-invalid" : ""}`}
                        />
                        <ErrorMessage errors={errors} name={fieldName("firstName")} />
                    </div>
                </div>
                <div className="col-24 col-md-8 mb-3">
                    <div className="w-100 d-flex flex-wrap">
                        <label htmlFor={fieldId("middleName")} className="w-100 d-flex justify-content-start align-items-center mb-2">Middle Name <small className="d-inline-block ms-1">(Optional)</small></label>
                        <input type="text" placeholder="Middle Name" maxLength={100} id={fieldId("middleName")} defaultValue={formData.middleName}
                            {...register(fieldName("middleName"), { maxLength: 100 })}
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="col-24 col-md-8 mb-3">
                    <div className="w-100 d-flex flex-wrap">
                        <label htmlFor={fieldId("lastName")} className="w-100 d-flex justify-content-start align-items-center mb-2">Last Name <small className="d-inline-block text-danger ms-1">(Required)</small></label>
                        <input type="text" placeholder="Last Name" maxLength={100} id={fieldId("lastName")} defaultValue={formData.lastName}
                            {...register(fieldName("lastName"), { required: "Enter your last name", maxLength: 100 })}
                            className={`form-control ${fieldError("lastName", errors) ? "is-invalid" : ""}`}
                        />
                        <ErrorMessage errors={errors} name={fieldName("lastName")} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default FullName;