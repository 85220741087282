import { failedResponseHandler, getAPIDefinition, succeedResponseHandler } from './helpers/apiHelper';
import { getRingsideClientId } from "../../../helpers/analyticsHelper";

const proposalEnquiriesAPIDefinition = getAPIDefinition(process.env.REACT_APP_MICROSERVICE_ENQUIRIES_URL);
proposalEnquiriesAPIDefinition.interceptors.response.use(succeedResponseHandler, failedResponseHandler);

export const API_GetEnquiryId = (enquiryType: number, quoteid: any, quoteEnquiryid: any, salespersonId: any, directfinance: boolean, origin: string) => {
    let headers = null;

    if (origin) {
        headers = {
            'LO-ORIGINAL-HOSTNAME': origin
        };
    }

    return proposalEnquiriesAPIDefinition.get(
        `/api/proposalenquiries/getenquiryid?enquiryType=${enquiryType}&quoteid=${quoteid}&quoteEnquiryid=${quoteEnquiryid}&salespersonId=${salespersonId}&directfinance=${directfinance}`,
        {
            headers: headers
        });
}

export const API_UpdateEnquiry = (proposalEnquiryid: any, proposalData: any) => {
    const payload = {
        proposalEnquiryid: proposalEnquiryid,
        ringsideClientId: getRingsideClientId(),
        data: JSON.stringify(proposalData)
    };

    return proposalEnquiriesAPIDefinition.post('/api/proposalenquiries/updateenquiry', payload);
}

export const API_CompleteEnquiry = (proposalEnquiryid: any, proposalData: any) => {
    const payload = {
        proposalEnquiryid: proposalEnquiryid,
        ringsideClientId: getRingsideClientId(),
        data: JSON.stringify(proposalData)
    };

    return proposalEnquiriesAPIDefinition.post('/api/proposalenquiries/completeenquiry', payload);
}