import React, { useContext, useEffect, ReactNode } from 'react';
import Header from '../structure/header';
import Footer from '../structure/footer';
import ToTop from '../structure/to-top';
import { ConfigContext } from '../../App';
interface IProps {
    children: ReactNode;
    className?: string;
}

const MainLayout: React.FC<IProps> = ({ children, className }) => {
    const config = useContext(ConfigContext);

    useEffect(() => {
       window.scrollTo(0, 0);

        // Declare theme colours
        document.documentElement.style.setProperty('--primaryBg', config.theme.colors.primaryBg);
        document.documentElement.style.setProperty('--primaryBgHover', config.theme.colors.primaryBgHover);
        document.documentElement.style.setProperty('--secondaryBg', config.theme.colors.secondaryBg);
        document.documentElement.style.setProperty('--secondaryBgHover', config.theme.colors.secondaryBgHover);
    }, []);

    return (
        <>
            <Header />
            <main className={className}>{children}</main>
            <Footer />
            <ToTop />
        </>
    );
}

export default MainLayout;