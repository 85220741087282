import { parse, isValid, differenceInYears, startOfToday } from "date-fns";
import { API_ValidatePhoneNumber } from '../environment/integration/api/DataLookupsAPI';

export const PhoneNumberIsValid = async (phoneNumber: string | null, isInternal: boolean = false) => {
    if (phoneNumber) {
        if (isInternal) {
            let value = phoneNumber.replace(/\s+/g, "");
            return /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/.test(value);
        } else {
            return new Promise((resolve) => {
                API_ValidatePhoneNumber(phoneNumber).then((response) => {
                    resolve(response.data.IsValid);
                }).catch((error) => {
                    resolve(true);
                });
            });
        }
    }
}

export const EmailAddressIsValid = (email: string | null, allowEmpty: boolean = true) => {
    if (email) {
        let value = email.replace(/\s+/g, "");
        return /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,15}|[0-9]{1,3})?$/.test(value);
    }
    else if (!allowEmpty) {
        return false;
    }

    return true;
}

export const IsValidDate = (date: string): boolean => {
    const dateObj = parse(date, 'dd/MM/yyyy', new Date());

    return isValid(dateObj);
}

export const IsOver18 = (date: string): boolean => {
    const dateLeft = startOfToday();
    const dateRight = parse(date, 'dd/MM/yyyy', new Date());

    return differenceInYears(dateLeft, dateRight) >= 18;
}

export const errorMessagesToString = (errors: object) => {
    let errorsString = '';
    let forCount = 1;
    let errorCount = Object.keys(errors).length;

    for (let [key, value] of Object.entries(errors)) {
        if (Array.isArray(value) && value.length > 0) {
            let arrayCount = 1;
            value.map(error => {
                errorsString += "[" + arrayCount + "]";
                errorsString += Object.keys(error).join('|');
                arrayCount++;
            })
        } else {
            errorsString += key;

            if (forCount != errorCount) {
                errorsString += "|"; 
            }
        }

        forCount++;
    }

    return errorsString;
}