import React from 'react';
import { useFormContext } from "react-hook-form";
import ErrorMessage from '../../layout/forms/error-message';

import { getFieldName, getFieldError, getFieldId } from '../../../helpers/fieldArrayHelper';

interface IProps {
    defaultValue: any;
    gender: string;
    collectionName: string;
    index: number;
}

const MaritalStatusSelector: React.FC<IProps> = ({ defaultValue, gender, collectionName, index }) => {
    const { register, formState: { errors } } = useFormContext();

    const fieldId = getFieldId("maritalStatus", collectionName, index);
    const fieldName = getFieldName("maritalStatus", collectionName, index);
    const fieldError = getFieldError("maritalStatus", collectionName, index, errors);

    return (
        <div className="w-100 d-flex flex-wrap">
            <label htmlFor={fieldId} className="w-100 d-flex justify-content-start align-items-center mb-2">Marital Status <small className="d-inline-block text-danger ms-1">(Required)</small></label>
            <select id={fieldId} defaultValue={defaultValue}
                {...register(fieldName, { required: "Select a martial status" })}
                className={`form-select ${fieldError ? "is-invalid" : ""}`}
            >
                <option value="">Select</option>
                <option value="1">Single</option>
                <option value="2">Married</option>
                <option value="3">Divorced</option>
                <option value="4">Separated</option>
                {gender !== "1" && <option value="5">Widow</option>}
                {gender !== "2" && <option value="6">Widower</option>}
                <option value="7">Living with Partner</option>
            </select>
            <ErrorMessage errors={errors} name={fieldName} />
        </div>
    );
}

export default MaritalStatusSelector;