import React from 'react';
import moment from 'moment';
import { Application, DatePicker } from "react-rainbow-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
interface IProps {
    selectedDate: string;
    valid?: boolean;
    onDateChange: any;
}
    
//TODO: remove\rename dob class
const DateInput: React.FC<IProps> = ({ selectedDate, valid = true, onDateChange }) => {
    let currentDateMoment = moment().toDate();
    let selectedDateMoment = moment(selectedDate, "DD/MM/YYYY");
    let selectedDateFormatted = selectedDateMoment.toDate();
    
    const initialState = {
        date: selectedDateFormatted,
        locale: { name: 'en-GB', label: 'English (UK)' },
    };
    const theme = {
        rainbow: {
            palette: {
                brand: '#212529',
            },
        },
    };
    
    return (
        <Application className="w-100" theme={theme}>
            <DatePicker
                required
                className={`form-date-picker ${valid ? "" : "is-invalid"}`}
                value={selectedDate ? initialState.date : ''}
                onChange={(value) => onDateChange(value)}
                icon={<FontAwesomeIcon icon={faCalendarDays} />}
                locale={initialState.locale.name}
                maxDate={currentDateMoment}
            />
        </Application>
    ); 
}

export default DateInput;