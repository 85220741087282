import React, { useRef, useState } from 'react';
import { useFormContext } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import styles from './postcode-lookup.module.scss';
import styled from 'styled-components';

import { API_LookupPostcode } from '../../../environment/integration/api/DataLookupsAPI';

const InputWrapper = styled.div`
    ul {
        li {
            a:hover {
                background-color: ${props => props.theme.colors.primaryBg}!important;
            }
        }
    }
`;

interface IProps {
    onAddressSelected: any;
    onManualSelected: any;
    viewAddress: boolean;
    valid?: boolean;
}

//TODO: check logic including setTimeout
//TODO: don't use index as key
const PostcodeLookup: React.FC<IProps> = ({ onAddressSelected, onManualSelected, viewAddress, valid = true }) => {
    const searchRef = useRef<HTMLInputElement>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isActive, setIsactive] = useState<boolean>(false);
    const [searchResults, setSearchResults] = useState([]);

    let setTimeoutId = null;

    const handleSearchInit = (evt) => {
        const searchQuery = evt.target.value.toString();

        setIsactive(false);
        setSearchResults([]);

        //Reset any pending (executing) requests
        if (setTimeoutId) {
            clearTimeout(setTimeoutId);
        }

        if (searchQuery && searchQuery.length >= 4) {
            setIsLoading(true);

            setTimeoutId = setTimeout(function () {
                API_LookupPostcode(searchQuery)
                    .then(function (results) {
                        setIsactive(true);
                        setSearchResults(results.data);
                        setIsLoading(false);

                        setTimeoutId = null;
                    });
            }, 50);
        }
    };

    const handleSearchBlur = () => {
        setTimeout(handleSearchClose, 250);
    }

    const handleSearchClose = () => {
        setIsactive(false);
        setIsLoading(false);
        setSearchResults([]);
    }

    const handleAddressSelect = (address, evt) => {
        evt.stopPropagation();

        if (onAddressSelected) {
            onAddressSelected(address);
            handleSearchClose();
            searchRef.current.value = "";
        }
    }

    return (
        <>
            <label htmlFor="postcodeLookup" className="w-100 d-flex justify-content-start align-items-center mb-2">Address Lookup</label>
            <InputWrapper className={styles['input-wrapper']}>
                <div className="input-group">
                    <input ref={searchRef} type="text" autoComplete="off" className={`form-control p-3 ${valid ? "border-primary" : "is-invalid"}`} id="postcodeLookup" placeholder="E.g. 'CR0 3RL' or '36 Factory Lane'" onFocus={handleSearchInit} onKeyUp={handleSearchInit} onBlur={handleSearchBlur} />
                    {!viewAddress && <button className={`btn ${valid ? "btn-outline-primary" : "btn-outline-danger"} d-none d-md-block`} onClick={() => onManualSelected()} type="button">Enter address manually</button>}
                </div>
                {isLoading && <FontAwesomeIcon icon={faSpinner} className={`fa-spin ${styles['input-loading']}`} />}
                <p className="form-text text-warning mt-1 mb-0">Start typing an address or a postcode <span className={`${viewAddress ? "d-none": ""} d-md-none`}>.<button type="button" onClick={() => onManualSelected()} className="btn btn-text p-0 text-decoration-underline">Click here</button> to enter address manually</span></p>
                <div className={`${styles['input-list']} ${isActive ? styles['is-active'] : ""}`}>
                    <ul>
                        {searchResults.map((item, index) => {
                            return (<li key={`result-${index}`} onClick={(evt) => { handleAddressSelect(item, evt) }}>{item.summaryline}</li>);
                        })}
                    </ul>
                </div>
            </InputWrapper>
        </>
    );
}

export default PostcodeLookup;