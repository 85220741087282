import React, { useState, useEffect } from "react";
import { Button } from '../../forms/inputs/buttons';
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import style from './to-top.module.scss';

import { listenToScroll } from "../../../../helpers/helper";

const ToTop: React.FC = () => {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, []);

    const handleToTop = () => {
        window.scrollTo(0, 0);
    }

    const onScroll = () => {
        if (listenToScroll(0.15)) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    }

    return (
        <Button className={`btn btn-primary btn-fluid ${style['to-top']} justify-content-center align-items-center ${scrolled ? style['is-active'] : ''}`} text="" icon={faChevronUp} onClick={handleToTop}></Button>
    );
}

export default ToTop;