import { combineReducers } from "redux";
import { initialFormData } from "./initialState";

export function isGaTracking(state = false, action) {
    switch (action.type) {
        case "UPDATE_GA_TRACKING":
            return action.tracking;
        default:
            return state;
    }
}

export function isInternal(state = null, action) {
    switch (action.type) {
        case "UPDATE_INTERNAL":
            return action.internal;
        default:
            return state;
    }
}

export function stepIndicator(state = 1, action) {
    switch (action.type) {
        case "UPDATE_INDICATOR":
            return action.step;
        default:
            return state;
    }
}

export function formData(state = initialFormData, action) {
    switch (action.type) {
        case "UPDATE_FIELDS":
            return {
                ...state,
                [action.step]: action.data,
            };
        case "RESET_FIELDS":
            return initialFormData;
        default:
            return state;
    }
}

const siteReducers = combineReducers({
    isGaTracking,
    isInternal,
    stepIndicator,
    formData
});

const reducer = (state, action) => {
    return siteReducers(state, action);
}

export default reducer;