import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children;

interface IButtonProps {
    id?: string,
    className: string,
    text: string,
    icon?: IconProp
    onClick?: any,
    link?: string,
    disabled?: boolean,
    location?: string
}

const Button: React.FC<IButtonProps> = ({ id, className, text, icon, onClick, link, disabled, location = 'before' }) => {
    return (

        <ConditionalWrapper condition={link} wrapper={children => <Link to={link!} className="text-decoration-none">{children}</Link>}>
            <button id={id} className={className} onClick={onClick} disabled={disabled}>
                {icon != null && location === 'before' ? <FontAwesomeIcon icon={icon} className="me-1 ms-0" /> : ""}
                {text}
                {icon != null && location === 'after' ? <FontAwesomeIcon icon={icon} className="me-0 ms-1" /> : ""}
            </button>
        </ConditionalWrapper>

    );
}

export { Button }