import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import PostcodeLookup from '../postcode-lookup/postcode-lookup';
import ErrorMessage from '../../layout/forms/error-message';

import { getFieldError, getFieldId, getFieldName } from '../../../helpers/fieldArrayHelper';

interface IProps {
    formData: any;

    collectionName: string | null;
    index: number;
    isPopulated?: boolean;
}

//TODO: should postcodeLookup id include idx?
//TODO: remove timestamp?
const AddressInput: React.FC<IProps> = ({ formData, collectionName, index, isPopulated = false }) => {
    const [viewAddress, setViewAddress] = useState<boolean>(isPopulated || formData.address1 ? true : false);
    const { setValue, register, clearErrors, formState: { errors } } = useFormContext();

    const onAddressSelected = (address) => {
        //TODO: check addess format and fields mapping logic, compare with mvc
        setValue(fieldName("postcode"), address.postcode);
        setValue(fieldName("address1"), address.addressline1);
        setValue(fieldName("address2"), address.addressline2);
        setValue(fieldName("address3"), address.addressline3);
        setValue(fieldName("town"), address.posttown);
        setValue(fieldName("county"), address.county);
        setValue(fieldName("timestamp"), Date.now());
        setViewAddress(true);
        clearErrors(fieldName("timestamp"));
    }

    const onManualSelected = () => setViewAddress(true);

    const fieldName = (name) => {
        return getFieldName(name, collectionName, index);
    }

    const fieldId = (name) => {
        return getFieldId(name, collectionName, index);
    }

    const fieldError = (name: string, errors: any) => {
        return getFieldError(name, collectionName, index, errors);
    }

    useEffect(() => {
        setValue(fieldName("timestamp"), isPopulated ? Date.now() : "");
        setViewAddress(isPopulated || formData.address1 ? true : false);
        clearErrors(fieldName("timestamp"));
    }, [isPopulated]);
    
    return (
        <>
            <div className="row">
                <div className="col-24 mb-5">
                    <input type="hidden" defaultValue={formData.timestamp} {...register(fieldName("timestamp"), { required: true } )} />
                    <PostcodeLookup onAddressSelected={onAddressSelected} onManualSelected={onManualSelected} viewAddress={viewAddress} valid={fieldError("timestamp", errors) ? false : true} />
                    <ErrorMessage errors={errors} name={fieldName("timestamp")} message="Address is required. Plese use the address lookup or click enter address manually." />
                </div>
            </div>
            {viewAddress &&
                <div className="w-100">
                    <div className="row">
                        <div className="col-24 col-md-8 mb-3">
                            <label htmlFor={fieldId("address1")} className="w-100 d-flex justify-content-start align-items-center mb-2">Address <small className="d-inline-block text-danger ms-1">(Required)</small></label>
                            <input type="text" placeholder="Number and street" maxLength={100} id={fieldId("address1")} defaultValue={formData.address1}
                                {...register(fieldName("address1"), { required: "Enter number and street", maxLength: 100 })}
                                className={`form-control ${fieldError("address1", errors) ? "is-invalid" : ""}`}
                            />
                            <ErrorMessage errors={errors} name={fieldName("address1")} />
                        </div>
                        <div className="col-24 col-md-8 mb-3">
                            <label htmlFor={fieldId("address2")} className="w-100 d-flex justify-content-start align-items-center mb-2">Address Line 2 <small className="d-inline-block ms-1">(Optional)</small></label>
                            <input type="text" placeholder="Address 2" maxLength={100} id={fieldId("address2")} defaultValue={formData.address2}
                                {...register(fieldName("address2"), { maxLength: 100 })}
                                className="form-control"
                            />
                        </div>
                        <div className="col-24 col-md-8 mb-3">
                            <label htmlFor={fieldId("address3")} className="w-100 d-flex justify-content-start align-items-center mb-2">Address Line 3 <small className="d-inline-block ms-1">(Optional)</small></label>
                            <input type="text" placeholder="Address 3" maxLength={100} id={fieldId("address3")} defaultValue={formData.address3}
                                {...register(fieldName("address3"), { maxLength: 100 })}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-24 col-md-8 mb-3">
                            <label htmlFor={fieldId("town")} className="w-100 d-flex justify-content-start align-items-center mb-2">Town / City <small className="d-inline-block text-danger ms-1">(Required)</small></label>
                            <input type="text" placeholder="Town" maxLength={100} id={fieldId("town")} defaultValue={formData.town}
                                {...register(fieldName("town"), { required: "Enter a town", maxLength: 100 })}
                                className={`form-control ${fieldError("town", errors) ? "is-invalid" : ""}`}
                            />
                            <ErrorMessage errors={errors} name={fieldName("town")} />
                        </div>
                        <div className="col-24 col-md-8 mb-3">
                            <label htmlFor={fieldId("county")} className="w-100 d-flex justify-content-start align-items-center mb-2">County <small className="d-inline-block text-danger ms-1">(Required)</small></label>
                            <input type="text" placeholder="County" maxLength={100} id={fieldId("county")} defaultValue={formData.county}
                                {...register(fieldName("county"), { required: "Enter a country", maxLength: 100 })}
                                className={`form-control ${fieldError("county", errors) ? "is-invalid" : ""}`}
                            />
                            <ErrorMessage errors={errors} name={fieldName("county")} />
                        </div>
                        <div className="col-24 col-md-8 mb-3">
                            <label htmlFor={fieldId("postcode")} className="w-100 d-flex justify-content-start align-items-center mb-2">Postcode <small className="d-inline-block text-danger ms-1">(Required)</small></label>
                            <input type="text" placeholder="Postcode" maxLength={10} id={fieldId("postcode")} defaultValue={formData.postcode}
                                {...register(fieldName("postcode"), { required: "Enter a postcode", maxLength: 10 })}
                                className={`form-control ${fieldError("postcode", errors) ? "is-invalid" : ""}`}
                                onChange={() => { setValue(fieldName("timestamp"), Date.now()); }}
                            />
                            <ErrorMessage errors={errors} name={fieldName("postcode")} />
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default AddressInput;