import React, { useState } from 'react';
import { useFormContext } from "react-hook-form";
import AddressInput from '../address/address-input';
import SortcodeLookup from '../sortcode-lookup';
import ErrorMessage from '../../layout/forms/error-message';

interface IProps {
    formData: any;
}

const BankDetails: React.FC<IProps> = ({ formData }) => {
    const { setValue, register, formState: { errors } } = useFormContext();
    const [usedLookup, setUsedLookup] = useState<boolean>(false);

    const onBankSelected = (address) => {
        //TODO: check addess format and fields mapping logic, compare with mvc

        setValue("bankName", address.name);
        setValue("postcode", address.postcode);
        setValue("address1", address.address1);
        setValue("address2", address.address2);
        setValue("address3", address.address3);
        setValue("town", address.town);
        setValue("county", address.county);
        setUsedLookup(true);
    };

    return (
        <>
            <div className="row">
                <div className="col-24">
                    <h2 className="w-100 d-block h3 mb-3">Bank Details</h2>
                    <p className="mb-3 mb-md-4 mb-lg-5 text-danger">Payments must be made from the applicants main account</p>
                </div>
            </div>
            <div className="row">
                <div className="col-24">
                    <h3 className="w-100 d-block h4 mb-3">Bank Information</h3>
                </div>
            </div>
            <div className="row">
                <div className="col-24 col-md-12 col-lg-8">
                    <SortcodeLookup onBankSelected={onBankSelected} selectedSortCode={formData.sortCode} register={register} errors={errors} />
                </div>
            </div>
            <div className="row">
                <div className="col-24 mb-5">
                    <p className="form-text text-warning mt-1 mb-0">Start typing your sort code without any spaces</p>
                </div>
            </div>
            <div className="row">
                <div className="col-24 col-md-8 mb-3 mb-md-5">
                    <label htmlFor="bankName" className="w-100 d-flex justify-content-start align-items-center mb-2">Bank Name <small className="d-inline-block text-danger ms-1">(Required)</small></label>
                    <input type="text" aria-describedby="bankName" placeholder="Your Bank Name" maxLength={100} id="bankName" defaultValue={formData.bankName}
                        {...register("bankName", { required: "Enter a bank name", maxLength: 100 })}
                        className={`form-control ${errors.bankName ? "is-invalid" : ""}`}
                    />
                    <ErrorMessage errors={errors} name="bankName" />
                </div>
                <div className="col-24 col-md-8 mb-3 mb-md-5">
                    <label htmlFor="accountName" className="w-100 d-flex justify-content-start align-items-center mb-2">Account Name <small className="d-inline-block text-danger ms-1">(Required)</small></label>
                    <input type="text" aria-describedby="accountName" placeholder="Your Account Name" maxLength={100} id="accountName" defaultValue={formData.accountName}
                        {...register("accountName", { required: "Enter an account name", maxLength: 100 })}
                        className={`form-control ${errors.accountName ? "is-invalid" : ""}`}
                    />
                    <ErrorMessage errors={errors} name="accountName" />
                </div>
                <div className="col-24 col-md-8 mb-5">
                    <label htmlFor="accountNumber" className="w-100 d-flex justify-content-start align-items-center mb-2">Account Number <small className="d-inline-block text-danger ms-1">(Required)</small></label>
                    <input type="number" inputMode="numeric" pattern="[0-9]*" aria-describedby="accountNumber" placeholder="Your Account Number" maxLength={8} id="accountNumber" defaultValue={formData.accountNumber}
                        //TODO: cover minLength+maxLength+pattern just with pattern rule?
                        {...register("accountNumber",
                            {
                                required: "Enter an account number",
                                minLength: { value: 7, message: 'Minimum 7 digits required' },
                                maxLength: { value: 8, message: 'Maximum 8 digits required' },
                                pattern: { value: /^[0-9]*$/, message: 'Please enter numbers only, without spaces or dashes' }
                            })}
                        className={`form-control ${errors.accountNumber ? "is-invalid" : ""}`}
                    />
                    <ErrorMessage errors={errors} name="accountNumber" />
                </div>
            </div>
            <div className="row">
                <div className="col-24">
                    <h3 className="w-100 d-block h4 mb-3">Bank Address</h3>
                </div>
            </div>
            <AddressInput formData={formData} index={0} collectionName={null} isPopulated={usedLookup} />
            <div className="row">
                <div className="col-24 col-md-12 col-lg-8 mb-5">
                    <label htmlFor="bankYears" className="w-100 d-flex justify-content-start align-items-center mb-2">Years with Bank  <small className="d-inline-block text-danger ms-1">(Required)</small></label>
                    <input type="number" inputMode="numeric" pattern="[0-9]*" aria-describedby="bankYears" placeholder="Years" maxLength={3} id="bankYears" defaultValue={formData.bankYears}
                        {...register("bankYears", { required: "Enter a number of years", max: 100, pattern: /^[0-9]*$/ })}
                        className={`form-control ${errors.bankYears ? "is-invalid" : ""}`}
                    />
                    <ErrorMessage errors={errors} name="bankYears" />
                </div>
            </div>
            <div className="row">
                <div className="col-24">
                    <p className="mb-3">Please confirm that you are authorised to set-up a direct debit and to allow payments to be made using the bank details provided, without the permission of any other account holders.</p>
                    <div className="form-check form-check-inline">
                        <input type="checkbox" id="authorised" defaultChecked={formData.authorised} {...register("authorised", { required: "Please authorised the set-up of a direct debit to allow payments to be made using the bank details provided" })} className={`form-check-input ${errors.authorised ? "is-invalid" : ""}`}></input>
                        <label className="form-check-label d-flex justify-content-center align-items-center pe-4" htmlFor="authorised"><strong>I am authorised to set-up a direct debit and to allow payments to be made using the bank details provided</strong></label>
                    </div>
                    <ErrorMessage errors={errors} name="authorised" />
                </div>
            </div>
        </>
    );
}

export default BankDetails;