import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import AddressHistoryItem from './address-history-item';

import { getTotalAddressesHistory } from '../helpers/ProposalDataHelper';

interface IProps {
    formData: any;
    minHistoryMonths: number;
    parentContainer: string;
}

//TODO: unify logic with employment history
const AddressHistory: React.FC<IProps> = ({ formData, minHistoryMonths, parentContainer }) => {
    const { unregister } = useFormContext();

    const collectionName = (parentContainer) ? `${parentContainer}.addresses` : "addresses";

    const [addresses, setAddresses] = useState([]);
    const [historyUpdated, setHistoryUpdated] = useState<any>(null);

    useEffect(() => {
        if (!formData.addresses) {
            appendAddress({});
        }
        else {
            //TODO: Remove empty items on form data?
            const addressHistory = formData.addresses.filter(item => item !== null).map((value, index) => {
                return {
                    address1: value.address1,
                    address2: value.address2,
                    address3: value.address3,
                    town: value.town,
                    county: value.county,
                    postcode: value.postcode,
                    fromDate: value.fromDate,
                    timeAtAddressMonths: value.timeAtAddressMonths,
                    timeAtAddressYears: value.timeAtAddressYears,
                    totalMonthsAtAddress: value.totalMonthsAtAddress
                }
            });

            setAddresses(addressHistory);
        }
    }, []);

    useEffect(() => {
        if (historyUpdated) {
            refreshHistoryItemsList();
        }
    }, [historyUpdated]);

    const appendAddress = (props) => {
        setAddresses([...addresses, props]);
    };

    const onTimePeriodUpdate = (totalMonths, index) => {
        let updatedAddresses = [...addresses.slice(0, index),
        {
            totalMonthsAtAddress: totalMonths
        }];

        if (totalMonths != null && totalMonths >= 0) {
            updatedAddresses = [...updatedAddresses, ...addresses.slice(index + 1)];
        }

        setAddresses(updatedAddresses);
        setHistoryUpdated(Date.now());
    }

    const refreshHistoryItemsList = () => {
        if (addresses.length > 0) {
            let items = addresses.slice(0); //create new array instance here
            let totalHistory = getTotalAddressesHistory(items);

            if (totalHistory < minHistoryMonths && (items[items.length - 1].totalMonthsAtAddress !== null && items[items.length - 1].totalMonthsAtAddress >= 0)) {
                appendAddress({});
            }
            else {
                let itemsCountToRemove = 0;

                while (items.length > 1 && totalHistory >= minHistoryMonths && totalHistory - (items[items.length - 1].totalMonthsAtAddress ?? 0) >= minHistoryMonths) {
                    items = items.slice(0, -1);
                    totalHistory = getTotalAddressesHistory(items);

                    itemsCountToRemove += 1;
                }

                if (itemsCountToRemove > 0) {
                    for (let i = 1; i <= itemsCountToRemove; i++) {
                        unregister(`${collectionName}[${addresses.length - i}]`);
                    }

                    setAddresses(addresses.slice(0, -itemsCountToRemove));
                }
            }
        }
    }

    return (
        <>
            {
                addresses.map((item, index) => {
                    return (
                        <AddressHistoryItem
                            key={index}
                            title={(index === 0) ? "Current Address" : `Previous Address ${index}`}
                            datesDisplayMode={index === 0 ? 1 : 2}
                            addressData={item}
                            collectionName={collectionName}
                            index={index}
                            onTimePeriodUpdate={(totalMonths) => { onTimePeriodUpdate(totalMonths, index) }}
                        />
                    );
                })
            }
        </>
    );
}

export default AddressHistory;