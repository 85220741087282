import React, { useState, useEffect, ReactNode } from 'react';
import { useCookies } from "react-cookie";

import { AppConfig } from '../interfaces/AppConfig.interface';
import { ConfigContext } from '../../components/App';

import { twentyTwentyConfig } from '../../infrastructure/themes/2020';
import { alliedConfig } from '../../infrastructure/themes/allied';
import { ouasConfig } from '../../infrastructure/themes/ouas';
import { jotunConfig } from '../../infrastructure/themes/jotun';
import { vroomConfig } from '../../infrastructure/themes/vroom';
import { mossBankConfig } from '../../infrastructure/themes/mossbank';
import { energyFinderConfig } from '../../infrastructure/themes/energyfinder';
import { fichtnerConfig } from '../../infrastructure/themes/fichtner';
import { stHelensConfig } from '../../infrastructure/themes/sthelens';
import { leasingLifeworksConfig } from '../../infrastructure/themes/leasinglifeworks';
import { kvlConfig } from '../../infrastructure/themes/kvl';
import { autotraderConfig } from '../../infrastructure/themes/autotrader';
import { genusConfig } from '../../infrastructure/themes/genus';
import { autofinitiConfig } from '../themes/autofiniti';
import { leasingoptionsConfig } from '../../infrastructure/themes/leasingoptions';
import { dunhammasseyConfig } from '../../infrastructure/themes/dunhammassey';
import { wcsgroupConfig } from '../../infrastructure/themes/wcsgroup';
import { leasingoptionsAtcConfig } from '../../infrastructure/themes/leasingoptionsatc';
import { matchMyMotorConfig } from '../../infrastructure/themes/matchmymotor';
import { rmtConfig } from '../../infrastructure/themes/rmt';
import { theCarHubConfig } from '../../infrastructure/themes/thecarhub';
import { communicorpConfig } from '../../infrastructure/themes/communicorp';
import { uniteTheUnionConfig } from '../../infrastructure/themes/unitetheunion';
import { caravanClubConfig } from '../../infrastructure/themes/caravanclub';
import { pneConfig } from '../../infrastructure/themes/pne';
import { defaultConfig } from '../../infrastructure/themes/default';

type IProps = {
    children: ReactNode
}

const ConfigProvider: React.FC<IProps> = ({ children }) => {
    const queryParams = new URLSearchParams(window.location.search);
    const [cookie, setCookie] = useCookies<string>(["tenantId"]);
    const [tenantId, setTenantId] = useState<string>(queryParams.get("tenantId") || cookie.tenantId || null);

    useEffect(() => {
        if (queryParams.get("tenantId")) {
            let tenantIdParam = queryParams.get("tenantId");
    
            setCookie("tenantId", tenantIdParam, {
                path: "/",
                sameSite: true
            });
            setTenantId(tenantIdParam);
        }
    }, []);

    const getConfig = (tenantId: string): AppConfig => {
        switch (tenantId ? tenantId.toUpperCase() : '') {
            case "420D9667-2D2C-4EE9-A523-1B5BF9CFBA09":
                return twentyTwentyConfig;
            case "D592A564-690C-4866-B6D0-1C933BF34EAB":
                return alliedConfig;
            case "2285A710-36D5-4C38-BB9A-2EBA84D587F8":
                return ouasConfig;
            case "5702938C-1B07-4DB3-BA38-33AA084918A3":
                return jotunConfig;
            case "54B81B26-9368-48F1-9CD1-BF84EA6536C2":
                return jotunConfig;
            case "0EF7CC4F-3417-4FA2-8A4B-D149B4654A5A":
                return jotunConfig;
            case "046CF621-67FC-4E68-8F19-5184692C4FE8":
                return vroomConfig;
            case "02356F99-3EE0-481B-BAD5-55F55E36A216":
                return mossBankConfig;
            case "180569C6-8862-453E-90DA-6329F9F5493B":
                return energyFinderConfig;
            case "8E0EAD71-03E5-46EC-B69C-6588BD0A382B":
                return fichtnerConfig;
            case "1BD96DFA-0ACF-4323-BE05-C9076D4D52E4":
                return fichtnerConfig;
            case "1A9AF8E1-CDB3-448D-9497-DBD4E344CE08":
                return fichtnerConfig;
            case "D0285825-E8B5-4CC5-B20B-6CC6D3C628DB":
                return stHelensConfig;
            case "1160740B-1D23-4282-8D43-7B8AFCDC6178":
                return leasingLifeworksConfig;
            case "C4C08E8F-4B12-4A9F-A231-81B3853738CF":
                return kvlConfig;
            case "E066375F-78DB-4B78-8C35-831FCB508167":
                return autotraderConfig;
            case "F7EB7D37-8D21-4D55-A3DA-846EFD26529D":
                return genusConfig;
            case "DB1B5B72-FBF0-46F7-9237-87BBBFADF24A":
                return autofinitiConfig;
            case "ED5C769D-E275-4352-B228-9D9277D91A2F":
                return leasingoptionsConfig;
            case "96ACB247-AB16-4FFC-AACD-A84833460CA4":
                return dunhammasseyConfig;
            case "9C6D2BFD-4452-4E7F-B0DE-AF82FECCD316":
                return wcsgroupConfig; 
            case "81BCEBAA-2E4D-4730-99D3-BFB2A82D8543":
                return leasingoptionsAtcConfig;
            case "6ECF9D28-09A4-4E1A-AA85-DD95369382EE":
                return matchMyMotorConfig;
            case "CD8A6E31-E38D-4ED7-9312-E38737B1562F":
                return rmtConfig;
            case "D19D0F58-0A80-4E87-898E-E5626D57028E":
                return theCarHubConfig;
            case "D39751C3-B185-448E-A356-E93823B270C1":
                return communicorpConfig;
            case "8EDDC1BA-2560-49B2-A418-F7641C66EAD1":
                return uniteTheUnionConfig;
            case "428ACCF1-E6E4-4559-BE4E-FBC9A77F18F7":
                return caravanClubConfig;
            case "AD8EF406-D4AC-4628-BF63-FE9878C1FB03":
                return pneConfig;
            default:
                return defaultConfig;
        }
    };

    const [config] = useState(getConfig(tenantId));

    return (
        <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
    )
}

export default ConfigProvider