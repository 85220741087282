import { HeaderTypes } from "../enums/HeaderTypes";
import { ProposalTypes } from "../enums/ProposalTypes";

export const GetHeaderBlockTitleTag = (headerType: HeaderTypes, primaryTitle: string) => {
    switch (headerType) {
        case HeaderTypes.h1:
            return <h1 className="h2 roboto-black text-center mb-0" dangerouslySetInnerHTML={{ __html: primaryTitle }}></h1>
        case HeaderTypes.h2:
            return <h2 className="h2 roboto-black text-center mb-0" dangerouslySetInnerHTML={{ __html: primaryTitle }}></h2>
        case HeaderTypes.h3:
            return <h3 className="h2 roboto-black text-center mb-0" dangerouslySetInnerHTML={{ __html: primaryTitle }}></h3>
        case HeaderTypes.h4:
            return <h4 className="h2 roboto-black text-center mb-0" dangerouslySetInnerHTML={{ __html: primaryTitle }}></h4>
        case HeaderTypes.h5:
            return <h5 className="h2 roboto-black text-center mb-0" dangerouslySetInnerHTML={{ __html: primaryTitle }}></h5>
        case HeaderTypes.h6:
            return <h6 className="h2 roboto-black text-center mb-0" dangerouslySetInnerHTML={{ __html: primaryTitle }}></h6>
        default:
            return;
    }
}

export const GetProposalTypeLabel = (proposalType: ProposalTypes) => {
    switch (proposalType) {
        case ProposalTypes.Personal:
            return "personal"
        case ProposalTypes.BusinessSole:
            return "soletrader"
        case ProposalTypes.BusinessPartner:
            return "partnership"
        case ProposalTypes.BusinessLtd:
            return "ltd"
        default:
            return;
    }
}

export const GetProposalStepLabel = (proposalType: ProposalTypes, stepNumber: number) => {
    if (proposalType === ProposalTypes.Personal) {
        switch (stepNumber) {
            case 1:
                return "start-application";
            case 2:
                return "personal-details-completed";
            case 3:
                return "personal-details-2-completed";
            case 4:
                return "address-history-completed";
            case 5:
                return "income-details-completed";
            case 6:
                return "expenditure-details-completed";
            case 7:
                return "employment-details-completed";
            case 8:
                return "bank-details-completed";
            case 9:
                return "completed-application";
            default:
                return;
        }
    } else if (proposalType  === ProposalTypes.BusinessSole) {
        switch (stepNumber) {
            case 1:
                return "start-application";
            case 2:
                return "business-details-completed";
            case 3:
                return "contact-and-address-completed";
            case 4:
                return "personal-details-completed";
            case 5:
                return "bank-details-completed";
            case 6:
                return "completed-application";
            default:
                return;
        }
    } else if (proposalType  === ProposalTypes.BusinessPartner) {
        switch (stepNumber) {
            case 1:
                return "start-application"; 
            case 2:
                return "business-details-completed";
            case 3:
                return "contact-and-address-completed";
            case 4:
                return "partner-1-details-completed";
            case 5:
                return "partner-2-details-completed";
            case 6:
                return "bank-details-completed";
            case 7:
                return "completed-application";
            default:
                return;
        }
    } else if (proposalType  === ProposalTypes.BusinessLtd) {
        switch (stepNumber) {
            case 1:
                return "start-application"; 
            case 2:
                return "business-details-completed";
            case 3:
                return "contact-and-address-completed";
            case 4:
                return "directors-list-completed";
            case 5:
                return "account-details-completed";
            case 6:
                return "bank-details-completed";
            case 7:
                return "completed-application";
            default:
                return;
        }
    } else {
        return;
    }
}