import React, { useState } from 'react';
import ErrorMessage from '../../layout/forms/error-message';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import styles from './sortcode-lookup.module.scss';
import styled from 'styled-components';

import { API_LookupSortcode } from '../../../environment/integration/api/DataLookupsAPI';

const InputWrapper = styled.div`
    ul {
        li {
            a:hover {
                background-color: ${props => props.theme.colors.primaryBg}!important;
            }
        }
    }
`;

type Props = {
    selectedSortCode: any,
    onBankSelected: any,
    register: any,
    errors: any
}

//TODO: check logic including setTimeout
//TODO: don't use index as key
const SortcodeLookup: React.FC<Props> = ({ selectedSortCode, onBankSelected, register, errors }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isActive, setIsActive] = useState<boolean>(false);
    const [searchResults, setSearchResults] = useState([]);

    const handleSearchInit = (evt) => {
        let searchQuery = evt.target.value.toString();

        if (searchQuery.length === 6) {
            setIsLoading(true);

            API_LookupSortcode(searchQuery)
                .then(function (results) {
                    setIsActive(true);
                    setSearchResults([results.data]);
                    setIsLoading(false);
                })
        } else {
            setIsActive(false);
            setSearchResults([]);
        }
    }

    const handleSearchBlur = () => {
        setTimeout(handleSearchClose, 250);
    }

    const handleSearchClose = () => {
        setIsActive(false);
        setIsLoading(false);
        setSearchResults([]);
    }

    const handleBankSelect = (bank, evt) => {
        //TODO: check bank format and fields mapping logic, compare with mvc
        evt.stopPropagation();

        if (onBankSelected) {
            onBankSelected({
                name: bank.longbank1 + ' - ' + bank.bankname,
                address1: bank.printaddr1,
                address2: bank.printaddr2,
                address3: bank.printaddr3 + (bank.printaddr4 ? (", " + bank.printaddr4) : ""),
                town: bank.printtown,
                county: bank.printcounty,
                postcode: bank.printpcode1 + ' ' + bank.printpcode2
            });
        }
    }

    return (
        <>
            <label htmlFor="sortCode" className="w-100 d-flex justify-content-start align-items-center mb-2">Sort Code Lookup <small className="d-inline-block text-danger ms-1">(Required)</small></label>
            <InputWrapper className={styles['input-wrapper']}>
                <input type="number" inputMode="numeric" pattern="[0-9]*" aria-describedby="sortCode" placeholder="Sort Code" maxLength={6} id="sortCode" defaultValue={selectedSortCode}
                    {...register("sortCode", { required: "Please enter a 6 digit number", minLength: 6, maxLength: 6, pattern: /^[0-9]*$/ })}
                    className={`form-control ${errors.sortCode ? "is-invalid" : ""}`}
                    onFocus={handleSearchInit}
                    onKeyUp={handleSearchInit}
                    onBlur={handleSearchBlur}
                />
                {isLoading ? <FontAwesomeIcon width="16" height="16" icon={faSpinner} className={`fa-spin ${styles['input-loading']}`} /> : ""}
                <div className={`${styles['input-list']} ${isActive ? styles['is-active'] : ""}`}>
                    <ul>
                        {searchResults.map((item, index) => {
                            return (<li key={`result-${index}`} onClick={(evt) => { handleBankSelect(item, evt) }}>{`${item.longbank1} - ${item.bankname}`}</li>);
                        })}
                    </ul>
                </div>
            </InputWrapper>
            <ErrorMessage errors={errors} name="sortCode" />
        </>
    );
}

export default SortcodeLookup;