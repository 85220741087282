import { LeasingDealTypes } from "src/enums/LeasingDealTypes";

export const GetThankYouPageUrl = (isInternal: boolean, enquiryid: string = null, leasingDealType: LeasingDealTypes = null, goal: string = null): string => {
    if (!isInternal) {
        return "/thankyou?goal=" + goal + (leasingDealType ? `&leasingDealType=${leasingDealType}` : "") + (enquiryid ? `&enquiryid=${enquiryid}` : "");
    }
    else {
        return "/thankyouinternal";
    }
}

export const GetFullUrl = () => {
    return window.location.protocol + '//' + window.location.host + window.location.pathname
}

export const GetFullUrlPath = () => {
    return window.location.pathname + window.location.search;
}