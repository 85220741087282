import { doGetJson } from "./helpers/apiHelper";

export class AuthenticationAPISettings {
    public static readonly baseUrl = process.env.REACT_APP_MICROSERVICE_AUTH_URL.replace(/\/+$/g, '');

    public static GetApiUrl = (relativeUrl: string): string => {
        return AuthenticationAPISettings.baseUrl + relativeUrl;
    }
}

export const API_IsInternal = async (fetchHeaders?) => {
    let url = AuthenticationAPISettings.GetApiUrl('/api/authentication/isinternal');
    return await doGetJson(url, fetchHeaders);
}