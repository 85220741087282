import React, { useState } from 'react';
import { useFormContext } from "react-hook-form";
import DateInput from './date';

import { IsValidDate } from '../../../helpers/validation';
import { getFieldError, getFieldName } from '../../../helpers/fieldArrayHelper';

interface IProps {
    value: string;
}

//TODO: highlight inpunts when validation failed
//TODO: dissallow future dates
//TODO: use ErrorMessage?
const DateEstablished: React.FC<IProps> = ({ value }) => {
    const { register, setValue, clearErrors, formState: { errors } } = useFormContext();

    const propertyName = "dateEstablished";
    const name = getFieldName(propertyName, null, null);
    const error = getFieldError(propertyName, null, null, errors);

    const [innerValue, setInnerValue] = useState<string>(value);

    const onDateChange = (date: Date) => {
        let selectedDate = date.toLocaleDateString("en-GB");

        setValue(name, selectedDate);
        setInnerValue(selectedDate);
        clearErrors(name);
    }

    return (
        <>
            <label className="w-100 d-flex justify-content-start align-items-center mb-2">
                Date Established <small className="d-inline-block text-danger ms-1">(Required)</small>
            </label>
            <div className={`w-100 d-flex flex-nowrap justify-content-start align-items-center ${error ? " is-invalid" : ""}`}>
                <input type="hidden"
                    {...register(name,
                        {
                            required: true,
                            validate: {
                                isDate: v => IsValidDate(v)
                            }
                        }
                    )}
                    defaultValue={innerValue}
                />
                <DateInput selectedDate={innerValue} valid={((error?.type === 'required' || error?.type === 'isDate')) ? false : true} onDateChange={onDateChange} />
            </div>
            {(error?.type === 'required' || error?.type === 'isDate') && <span className="invalid-feedback d-block">Select a date</span>}
        </>
    );
}

export default DateEstablished;