import React, { createContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from 'styled-components';

import ProposalPersonalPage from './pages/proposal_personal';
import ProposalBusinessPage from './pages/proposal_business';
import ProposalBusinessLtdPage from './pages/proposal_business_ltd';
import ProposalBusinessPartnerPage from './pages/proposal_business_partner';
import ProposalBusinessSolePage from './pages/proposal_business_sole';

import { withConfigContext } from '../infrastructure/configuration/withConfigContextHOC';
import { AppConfig } from '../infrastructure/interfaces/AppConfig.interface';
import { Theme } from '../infrastructure/interfaces/Theme.interface';
import { defaultConfig } from '../infrastructure/themes/default';
import { Analytics } from '@vercel/analytics/react';

export const ConfigContext = createContext<AppConfig>(defaultConfig);

interface IProps {
    theme: Theme;
}

const App: React.FC<IProps> = ({ theme }) => {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Routes>
                    <Route path="/proposal_personal" element={<ProposalPersonalPage />} />
                    <Route path="/proposal_business" element={<ProposalBusinessPage />} />
                    <Route path="/proposal_business_ltd" element={<ProposalBusinessLtdPage />} />
                    <Route path="/proposal_business_partner" element={<ProposalBusinessPartnerPage />} />
                    <Route path="/proposal_business_sole" element={<ProposalBusinessSolePage />} />
                </Routes>
            </Router>
            <Analytics />
        </ThemeProvider>
    );
}

export default withConfigContext(App);