// utils/analytics.js
import ReactGA from 'react-ga';
import { GetFullUrlPath } from './urlHelper';

export const initGA = () => {
    ReactGA.initialize('UA-5906257-3', { gaOptions: { cookieDomain: 'auto' }});
}

export const getGAClientId = () => {
    let gaClientId = null;

    ReactGA.ga((tracker) => {
        gaClientId = tracker.get('clientId')
    });
    return gaClientId;
}
export const getGARef = () => {
    return ReactGA.ga();
}

export const logPageView = () => {
    let pageUrl = GetFullUrlPath();
    ReactGA.set({ page: pageUrl })
    ReactGA.pageview(pageUrl)
}

export const logEvent = (category = '', action = '') => {
    if (category && action) {
        ReactGA.event({ category, action })
    }
}

export const logLabelEvent = (category = '', action = '', label = '') => {
    if (category && action && label) {
        ReactGA.event({ category, action, label })
    }
}
export const logException = (description = '', fatal = false) => {
    if (description) {
        ReactGA.exception({ description, fatal })
    }
}

export const isGaTrackingAllowed = (tenantId) => {
    let allowedTenants = [
        "ED5C769D-E275-4352-B228-9D9277D91A2F"// LO Website
    ];

    return allowedTenants.includes(tenantId.toUpperCase());
}

export const getRingsideClientId = () => {
    return (window.ringside !== 'undefined' && window.ringside?.rsc_visit_id) ? window.ringside.rsc_visit_id : null;
}