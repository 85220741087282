import React, { ReactNode } from 'react';
import { BlockThemeTypes } from '../../../../enums/BlockThemeTypes';
import { HeaderTypes } from '../../../../enums/HeaderTypes';
import { GetHeaderBlockTitleTag } from '../../../../helpers/enumsHelper';
import style from './header-block.module.scss';
import styled from 'styled-components';

interface HeaderBlockProps {
    backgroundColor?: string;
    type?: HeaderTypes;
    primaryTitle: string;
    secondaryTitle?: string;
    variant?: string;
    theme?: BlockThemeTypes;
    children?: ReactNode;
}

const HBlock = styled.div`
        hr {
            background-color: ${props => props.theme.colors.primaryBg};
        }
    `;

HBlock.defaultProps = {
    theme: {
        mainColor: 'yellow',
        hoverColor: 'pink'
    }
}

const HeaderBlock: React.FC<HeaderBlockProps> = ({ type = HeaderTypes.h3, primaryTitle, secondaryTitle, variant, theme = BlockThemeTypes.Light, children }) => {
    return (
        <HBlock>
            <div className={`${style['header-block']} text-center ${theme === BlockThemeTypes.Dark ? style['light'] : ""} ${variant ? variant : ''}`}>
                <hr className={`mt-0 mb-2`} />
                {secondaryTitle && <h4 className="body-normal mb-2 text-center" dangerouslySetInnerHTML={{ __html: secondaryTitle }}></h4>}
                {GetHeaderBlockTitleTag(type, primaryTitle)}
                {children}
            </div>
        </HBlock>
    );
};

export default HeaderBlock;