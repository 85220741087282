import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import PopoverHeader from 'react-bootstrap/PopoverHeader';
import PopoverBody from 'react-bootstrap/PopoverBody';

type Props = {
    id: string;
    trigger?: any;
    type?: any;
    title?: string;
    body: string;
    isInputGroup?: boolean;
    handleOnClick?: any;
}

//TODO: implement
const PopOver: React.FC<Props> = ({ id, trigger = null, type = "click", title, body, isInputGroup = false, handleOnClick = null }) => {
    const PopOverHtml = (
        <Popover id={`popover-positioned-${id}`}>
            {title ? <PopoverHeader>{title}</PopoverHeader> : ""}
            <PopoverBody>{body}</PopoverBody>
        </Popover>
    );
    const IconHtml = (
        <svg width={16} height={16} style={{height: 16}}aria-hidden="true" focusable="false" data-prefix="far" data-icon="info-circle" className="svg-inline--fa fa-info-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm0-338c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path></svg>
    );
    const OverlayHtml = () => (
        <OverlayTrigger trigger={type} rootClose key={id} placement="top" overlay={PopOverHtml}>
            <span className={isInputGroup ? "input-group-text py-0" : ""} onClick={handleOnClick ? handleOnClick : null}>
                {trigger ? trigger : IconHtml}
            </span>
        </OverlayTrigger>
    );

    return (<OverlayHtml />);
    return null;
}

export default PopOver;