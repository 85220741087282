import React from 'react';
import { connect } from 'react-redux';
import { useFormContext } from "react-hook-form";
import ErrorMessage from '../../layout/forms/error-message';

import { PhoneNumberIsValid } from '../../../helpers/validation';

interface IProps {
    isInternal: boolean;
    defaultValue: any;
}

const MobileNumber: React.FC<IProps> = ({ isInternal, defaultValue }) => {
    const { register, formState: { errors } } = useFormContext();

    return (
        <>
            <label htmlFor="mobileNumber" className="w-100 position-relative d-flex justify-content-start align-items-center z-xs mb-2">Mobile Number  <small className="d-inline-block text-danger ms-1">(Required)</small></label>
            <input type="tel" inputMode="tel" aria-describedby="mobileNumber" placeholder="Mobile Number" maxLength={20} id="mobileNumber" defaultValue={defaultValue}
                {...register("mobileNumber",
                    {
                        required: "Enter a mobile number",
                        maxLength: 20,
                        validate: async value => await PhoneNumberIsValid(value, isInternal) === true || 'Valid UK mobile number required'
                    }
                )}
                className={`form-control ${errors.mobileNumber ? "is-invalid" : ""}`}
            />
            <ErrorMessage errors={errors} name="mobileNumber" />
            <p className="form-text mt-1 mb-0">Example format: (07946013796)</p>
        </>
    );
}

export const mapStateToProps = (state) => {
    return {
        isInternal: state.isInternal,
    };
}

export default connect(mapStateToProps)(MobileNumber);