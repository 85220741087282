import React from 'react';
import WizardStep from '../wizard-step';
import BusinessDetails from '../business/business-details';

import { ProposalTypes } from '../../../enums/ProposalTypes';
import { ProposalStepProps } from '../types/ProposalStepProps';

const BusinessDetailsStep: React.FC<ProposalStepProps> = ({ id, stepNumber, formData, onBack, onForward }) => {
    return (
        <WizardStep id={id} stepNumber={stepNumber} onBack={onBack} onForward={onForward}>
            <BusinessDetails formData={formData} includeExtraDetails={id === ProposalTypes.BusinessLtd} />
        </WizardStep>
    );
}

export default BusinessDetailsStep;