export let listenToScroll = (scrolledCheck) => {
    const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop

    const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight

    const scrolled = winScroll / height

    if (scrolled > scrolledCheck) {
        return true;
    } else {
        return false;
    }
}