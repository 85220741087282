import React from 'react';
import { useFormContext } from "react-hook-form";
import DateEstablished from '../common/date-established';
import ErrorMessage from '../../layout/forms/error-message';

interface IProps {
    formData: any;
    includeExtraDetails: boolean;
}

//TODO: maxLength doesn't work for number inputs, oninput hadler required to fix. Should we use it at all? Might be rendered in different ways depending on browser\device
//TODO: isVatRegistered
//TODO: set vatNumber to null if isVatRegistered changed from 'yes' to 'no'?
//TODO: use includeExtraDetails flag or create separate Ltd control?
const BusinessDetails: React.FC<IProps> = ({ formData, includeExtraDetails }) => {
    const { register, watch, formState: { errors } } = useFormContext();

    const watchIsVatRegistered = watch("isVatRegistered", formData.isVatRegistered);

    return (
        <>
            <div className="row">
                <div className="col-24">
                    <h2 className="w-100 d-block h3 mb-3 mb-md-4 mb-lg-5">Business Details</h2>
                </div>
            </div>
            <div className="row">
                <div className="col-24 col-md-12 mb-3">
                    <label className="w-100 d-flex justify-content-start align-items-center mb-2">Are you VAT registered? <small className="d-inline-block text-danger ms-1">(Required)</small></label>
                    <div className="form-check form-check-inline mt-1 mb-2">
                        <input type="radio" value="yes" id="yes" defaultChecked={formData.isVatRegistered === 'yes'}
                            {...register("isVatRegistered", { required: "Select an option" })}
                            className={`form-check-input ${errors.isVatRegistered ? "is-invalid" : ""}`}
                        />
                        <label className="form-check-label d-block pe-4" htmlFor="yes">Yes</label>
                    </div>
                    <div className="form-check form-check-inline mt-1 mb-2">
                        <input type="radio" value="no" id="no" defaultChecked={formData.isVatRegistered === 'no'}
                            {...register("isVatRegistered", { required: "Select an option" })}
                            className={`form-check-input ${errors.isVatRegistered ? "is-invalid" : ""}`}
                        />
                        <label className="form-check-label d-block pe-4" htmlFor="no">No</label>
                    </div>
                    <ErrorMessage errors={errors} name="isVatRegistered" />
                </div>
                {watchIsVatRegistered === "yes" &&
                    <div className="col-24 col-md-12 mb-3">
                        <label htmlFor="vatNumber" className="w-100 d-flex justify-content-start align-items-center mb-2">VAT Number <small className="d-inline-block text-danger ms-1">(Required)</small></label>
                        <input type="number" pattern="[0-9]*" inputMode="numeric" aria-describedby="vatNumber" placeholder="VAT Number" maxLength={100} id="vatNumber" defaultValue={formData.vatNumber}
                            {...register("vatNumber", { required: "Enter VAT number", maxLength: 100, pattern: /^[0-9]*$/ })}
                            className={`form-control ${errors.vatNumber ? "is-invalid" : ""}`}
                        />
                        <ErrorMessage errors={errors} name="vatNumber" />
                    </div>
                }
            </div>
            <div className="row">
                <div className="col-24 col-md-12 col-lg-6 mb-3">
                    <label className="w-100 d-flex justify-content-start align-items-center mb-2" htmlFor="tradingName">Trading Name <small className="d-inline-block text-danger ms-1">(Required)</small></label>
                    <input type="text" aria-describedby="tradingName" placeholder="Trading Name" maxLength={100} id="tradingName" defaultValue={formData.tradingName}
                        {...register("tradingName", { required: "Enter a trading name", maxLength: 100 })}
                        className={`form-control ${errors.tradingName ? "is-invalid" : ""}`}
                    />
                    <ErrorMessage errors={errors} name="tradingName" />
                </div>
                <div className="col-24 col-md-12 col-lg-6 mb-3">
                    <label className="w-100 d-flex justify-content-start align-items-center mb-2" htmlFor="natureOfBusiness">Nature of Business <small className="d-inline-block text-danger ms-1">(Required)</small></label>
                    <input type="text" aria-describedby="natureOfBusiness" placeholder="Nature of Business" maxLength={100} id="natureOfBusiness" defaultValue={formData.natureOfBusiness}
                        {...register("natureOfBusiness", { required: "Enter a nature of business", maxLength: 100 })}
                        className={`form-control ${errors.natureOfBusiness ? "is-invalid" : ""}`}
                    />
                    <ErrorMessage errors={errors} name="natureOfBusiness" />
                </div>
                <div className="col-24 col-md-12 col-lg-6 mb-3">
                    <label className="w-100 d-flex justify-content-start align-items-center mb-2" htmlFor="totalFleetSize">Total Fleet Size <small className="d-inline-block text-danger ms-1">(Required)</small></label>
                    <input type="number" inputMode="numeric" pattern="[0-9]*" aria-describedby="totalFleetSize" placeholder="Total Fleet Size" min={0} id="totalFleetSize" defaultValue={formData.totalFleetSize}
                        {...register("totalFleetSize", { required: "Enter a number", min: 0, pattern: /^[0-9]*$/ })}
                        className={`form-control ${errors.totalFleetSize ? "is-invalid" : ""}`}
                    />
                    <ErrorMessage errors={errors} name="totalFleetSize" />
                </div>
                <div className="col-24 col-md-12 col-lg-6 mb-3 mb-md-0">
                    <DateEstablished value={formData.dateEstablished} />
                </div>
            </div>
            {
                includeExtraDetails &&
                <>
                    <div className="row">
                        <div className="col-24 col-md-12 mb-3 mb-md-0">
                            <label className="w-100 d-flex justify-content-start align-items-center mb-2" htmlFor="companyReg">Company Registration Number <small className="d-inline-block text-danger ms-1">(Required)</small></label>
                            <input type="number" inputMode="numeric" pattern="[0-9]*" aria-describedby="companyReg" placeholder="Company Registration Number" maxLength={100} id="companyReg" defaultValue={formData.companyReg}
                                {...register("companyReg", { required: "Enter a company registration number", maxLength: 100, pattern: /^[0-9]*$/ })}
                                className={`form-control ${errors.companyReg ? "is-invalid" : ""}`}
                            />
                            <ErrorMessage errors={errors} name="companyReg" />
                        </div>
                        <div className="col-24 col-md-12">
                            <label className="w-100 d-flex justify-content-start align-items-center mb-2" htmlFor="parentCompany">Parent Company <small className="d-inline-block ms-1">(Optional)</small></label>
                            <input type="text" aria-describedby="parentCompany" placeholder="Parent Company" maxLength={100} id="parentCompany" defaultValue={formData.parentCompany}
                                {...register("parentCompany", { maxLength: 100 })}
                                className={`form-control ${errors.parentCompany ? "is-invalid" : ""}`}
                            />
                            <ErrorMessage errors={errors} name="parentCompany" />
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default BusinessDetails;