import React from 'react';
import { useFormContext } from 'react-hook-form';
import BusinessPerson from './business-person';

interface IProps {
    formData: any;
}

//TODO: register add 2 times?
//TODO: unregister person fields if add !== "yes"
const AdditionalDirector: React.FC<IProps> = ({ formData }) => {
    const { register, watch } = useFormContext();

    const watchAdd = watch("add", formData.add);

    return (
        <>
            <div className="row">
                <div className="col-24">
                    <h3 className="w-100 d-block h4 mb-3">Additional Director</h3>
                </div>
            </div>
            <div className="row">
                <div className="col-24 col-md-12 mb-3">
                    <label className="w-100 d-block mb-2">Add Director</label>
                    <div className="form-check form-check-inline">
                        <input type="radio" value="yes" id="add_yes" defaultChecked={formData.add === "yes"}
                            {...register("add")}
                            className="form-check-input"
                        />
                        <label className="form-check-label d-block pe-4" htmlFor="add_yes">Yes</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input type="radio" value="no" id="add_no" defaultChecked={formData.add !== "yes"}
                            {...register("add")}
                            className="form-check-input"
                        />
                        <label className="form-check-label d-block pe-4" htmlFor="add_no">No</label>
                    </div>
                </div>
            </div>
            {
                watchAdd === "yes" &&
                <BusinessPerson formData={formData} />
            }
        </>
    );
}

export default AdditionalDirector;