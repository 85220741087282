import React, { useContext } from 'react';
import style from './footer.module.scss';

import { ConfigContext } from '../../../App';

//TODO: better approach for copyrightText required
const Footer: React.FC = () => {
    const config = useContext(ConfigContext);

    return (
    <footer className={`${style.footer} container-fluid py-3 py-md-5`}>
            <div className="container">
                <div className="row">
                    <div className="col-24"> 
                        {(config.theme.footer.copyrightText && config.theme.footer.copyrightText.length > 0) &&
                            config.theme.footer.copyrightText.map((item, idx) => (<p key={idx} dangerouslySetInnerHTML={{ __html: item }} className="text-center"></p>))
                        }
                        <p className="text-center">Leasing Options Limited - Registered in England and Wales. Company number: <strong>02487254</strong>. Registered with the Information Commissioner's Office as a Data Controller. Data Protection Registration number: <strong>Z7436776</strong>. <strong>Registered Office Address:</strong> Options House, Atkin Street, Worsley, Manchester, M28 3DG</p>
                        <p className="text-center"><strong>Telephone:</strong> 0161 703 8744. <strong>Email:</strong> <a href="mailto:info@leasingoptions.co.uk">info@leasingoptions.co.uk</a></p>
                    </div>
                </div>
           </div>
       </footer>
    );
}

export default Footer;