import React from 'react';
import { useFormContext } from 'react-hook-form';
import DateOfBirth from '../common/date-of-birth';
import FullName from '../common/full-name';
import GenderSelector from '../common/gender-selector';
import MaritalStatusSelector from '../common/marital-status-selector';
import NationalitySelector from '../common/nationality-selector';
import PropertyStatusSelector from '../common/property-status-selector';
import PropertyTenureSelector from '../common/property-tenure-selector';
import PropertyTypeSelector from '../common/property-type-selector';
import AddressHistory from '../address/address-history';

import { getFieldName } from '../../../helpers/fieldArrayHelper';

interface IProps {
    formData: any;
    collectionName?: string;
    index?: number;
}

//TODO: Join with PersonalDetails
//TODO: create common selector component?
//TODO: parentContainer doesn't work properly here so getFieldError can't find matches as property names are incorrect
//TODO: clear dependent values\unregister fields on watchPropertyStatus change?
const BusinessPerson: React.FC<IProps> = ({ formData, collectionName, index }) => {
    const { watch } = useFormContext();

    const watchGenderSelector = watch("gender", formData.gender);
    const watchPropertyStatus = watch(getFieldName("propertyStatus", collectionName, index), formData.propertyStatus);

    return (
        <>
            <FullName formData={formData} collectionName={collectionName} index={index} />
            <div className="row">
                <div className="col-24 col-md-8 mb-3">
                    <DateOfBirth value={formData.birthDate} collectionName={collectionName} index={index} verifyAge={true} />
                </div>             
                <div className="col-24 col-md-8 mb-3">
                    <NationalitySelector defaultValue={formData.nationality} collectionName={collectionName} index={index} />
                </div>
                <div className="col-24 col-md-24 col-lg-8 mb-3">
                    <GenderSelector defaultValue={formData.gender} collectionName={collectionName} index={index} />
                </div>  
            </div>
            <div className="row">
                <div className="col-24 col-md-12 col-lg-8 mb-3 mb-md-4">
                    <MaritalStatusSelector defaultValue={formData.maritalStatus} gender={watchGenderSelector} collectionName={collectionName} index={index} />
                </div>
            </div>
            <div className="row">
                <div className="col-24"><hr className="mt-0 mb-3 mb-md-4" /></div>
            </div>
            <div className="row">
                <div className="col-24 col-lg-8 mb-3 mb-lg-5">
                    <PropertyStatusSelector defaultValue={formData.propertyStatus} collectionName={collectionName} index={index} />
                </div>
                {(watchPropertyStatus === "1" || watchPropertyStatus === "3") &&
                    <div className="col-24 col-lg-8 mb-3 mb-3 mb-lg-5">
                        <PropertyTenureSelector defaultValue={formData.propertyTenure} collectionName={collectionName} index={index} />
                    </div>
                }
                <div className="col-24 col-lg-8 mb-3 mb-md-5 mb-lg-0">
                    <PropertyTypeSelector defaultValue={formData.propertyType} collectionName={collectionName} index={index} />
                </div>
            </div>
            <div className="row">
                <div className="col-24"><hr className="mt-0 mb-3 mb-md-4" /></div>
            </div>
            <AddressHistory formData={formData} minHistoryMonths={60} parentContainer={(collectionName) ? `${collectionName}[${index}]` : null} />
        </>
    );
}

export default BusinessPerson;