import React from 'react';
import { useFormContext } from "react-hook-form";
import ErrorMessage from '../../layout/forms/error-message';

import { getFieldName, getFieldError, getFieldId } from '../../../helpers/fieldArrayHelper';

interface IProps {
    defaultValue: any;
    collectionName: string;
    index: number;
}

const PropertyStatusSelector: React.FC<IProps> = ({ defaultValue, collectionName, index }) => {
    const { register, formState: { errors } } = useFormContext();

    const fieldId = getFieldId("propertyStatus", collectionName, index);
    const fieldName = getFieldName("propertyStatus", collectionName, index);
    const fieldError = getFieldError("propertyStatus", collectionName, index, errors);

    return (
        <>
            <label htmlFor={fieldId} className="w-100 d-flex justify-content-start align-items-center mb-2">Property Status <small className="d-inline-block text-danger ms-1">(Required)</small></label>
            <select id={fieldId} defaultValue={defaultValue}
                {...register(fieldName, { required: "Select a property status" })}
                className={`form-select ${fieldError ? "is-invalid" : ""}`}
            >
                <option value="">Select</option>
                <option value="1">Mortgaged</option>
                <option value="2">Living with Parents</option>
                <option value="3">Owned Outright</option>
                <option value="4">Rented</option>
                <option value="5">Council Tenant</option>
                <option value="6">Private Tenant</option>
                <option value="7">Living with Partner</option>
                <option value="8">Other</option>
            </select>
            <ErrorMessage errors={errors} name={fieldName} />
        </>
    );
}

export default PropertyStatusSelector;