import React from 'react';
import { FieldError, get } from "react-hook-form";

interface IProps {
    errors: object;
    name: string;
    message?: string;
}

const getErrorMessage = (error: FieldError, message: string) => {
    let result = "";

    if (error) {
        result = message || error.message;

        if (!result) {
            switch (error.type) {
                case "required":
                    result = "This field is required";
                    break;
                case "maxLength":
                    result = "This field exceed maxLength"
                    break;
                case "minLength":
                    result = "minLength limit wasn't achieved"
                    break;
                case "max":
                    result = "This field exceed max value"
                    break;
                case "min":
                    break;
                case "pattern":
                    result = "This field doesn't match required pattern"
                    break;
                case "validate":
                    break;

                case "isDate":
                    result = "Valid date is required"
                    break;

                default:
                    break;
            }
        }
    }

    return result;
}

//TODO: https://react-hook-form.com/api/useformstate/errormessage - wrap it with current component?
const ErrorMessage: React.FC<IProps> = ({ errors, name, message }) => {
    //TODO: get erros from context?
    /*    const { formState: { errors } } = useFormContext();*/

    const errorMessage = getErrorMessage(get(errors, name), message);

    return (
        <>
            {errorMessage && <span className="invalid-feedback d-block mt-1">{errorMessage}</span>}
        </>
    );
}

export default ErrorMessage;