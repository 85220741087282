import { ConfigContext } from '../../components/App';

export function withConfigContext(Component) {
    return function WrapperComponent(props) {
        return (
            <ConfigContext.Consumer>
                {state => <Component {...props} defaults={state.defaults} theme={state.theme}  />}
            </ConfigContext.Consumer>
        );
    };
}