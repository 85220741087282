import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import EmploymentHistoryItem from './employment-history-item';

import { getHistoryItemPeriodDetails, getTotalEmploymentHistory } from '../helpers/ProposalDataHelper';

interface IProps {
    formData: any;
    minHistoryMonths: number;
}

//TODO: include toDate prop with current Date value into initial item? What if current date changed while prop is in progress?
//TODO: unify logic with address history
const EmploymentHistory: React.FC<IProps> = ({ formData, minHistoryMonths }) => {
    const { unregister } = useFormContext();

    const collectionName = "employments";

    //used only for initial setup
    const [employments, setEmployments] = useState([]);

    useEffect(() => {
        if (!formData[collectionName]) {
            setEmployments([{}]);
        }
        else {
            const items = formData[collectionName].filter(item => item !== null).map(item => {
                return {
                    type: item.type,
                    employerName: item.employerName,
                    natureOfBusiness: item.natureOfBusiness,
                    position: item.position,
                    landline: item.landline,
                    fromDate: item.fromDate,
                    address1: item.address1,
                    address2: item.address2,
                    address3: item.address3,
                    county: item.county,
                    town: item.town,
                    postcode: item.postcode,
                    totalMonths: item.totalMonths
                };
            });

            for (let i = 0; i < items.length - 1; i++) {
                items[i + 1].toDate = items[i].fromDate;
            }

            setEmployments(items);
        }
    }, []);

    //TODO: on inital render called for each item. Prevent?
    const onTimePeriodUpdate = (fromDate: string, totalMonths: number, index: number) => {
        //clone employments up to current item
        let items = employments.slice(0, index + 1);

        if (items.length > 0) {
            //update current item
            items[index] = {
                ...items[index],
                fromDate: fromDate,
                totalMonths: totalMonths
            };

            //include the rest of items if current item has valid date
            if (totalMonths != null && totalMonths >= 0) {
                items = [...items, ...employments.slice(index + 1)];
            }

            //update next item
            if (index + 1 < items.length) {
                items[index + 1] = {
                    ...items[index + 1],
                    toDate: fromDate,
                    totalMonths: getHistoryItemPeriodDetails(items[index + 1].fromDate, fromDate).totalMonths
                };
            }

            let totalHistory = getTotalEmploymentHistory(items);

            if (totalHistory < minHistoryMonths && (items[items.length - 1].totalMonths !== null && items[items.length - 1].totalMonths >= 0)) {
                items.push({
                    toDate: items[items.length - 1].fromDate,
                    totalMonths: null
                });
            }
            else {
                while (items.length > 1 && totalHistory >= minHistoryMonths && totalHistory - (items[items.length - 1].totalMonths ?? 0) >= minHistoryMonths) {
                    items = items.slice(0, -1);
                    totalHistory = getTotalEmploymentHistory(items);
                }
            }
        }

        const removedItemsCount = employments.length - items.length;

        if (removedItemsCount > 0) {
            for (let i = 1; i <= removedItemsCount; i++) {
                unregister(`${collectionName}[${employments.length - i}]`);
            }
        }

        setEmployments(items);
    }

    return (
        <>
            <div className="row">
                <div className="col-24">
                    <h2 className="w-100 d-block h3 mb-0">Employment Details</h2>   
                </div>
            </div>      
            {
                //TODO: don't use index as key
                employments.map((item, index) => {
                    return (
                        <EmploymentHistoryItem
                            key={index}
                            title={(index === 0) ? "Current Employment" : `Previous Employment ${index}`}
                            employmentData={item}
                            collectionName={collectionName}
                            index={index}
                            onTimePeriodUpdate={onTimePeriodUpdate}
                        />
                    );
                })
            }
        </>
    );
}

export default EmploymentHistory;