import React from 'react';
import { useFormContext } from "react-hook-form";
import ErrorMessage from '../../layout/forms/error-message';

import { getFieldName, getFieldError, getFieldId } from '../../../helpers/fieldArrayHelper';

interface IProps {
    defaultValue: any;//TODO: change to number? use === comp operator then
    collectionName: string;
    index: number;
}

//TODO: should it register 3 times? check all radiobuttons
const PropertyTypeSelector: React.FC<IProps> = ({ defaultValue, collectionName, index }) => {
    const { register, formState: { errors } } = useFormContext();

    const fieldName = getFieldName("propertyType", collectionName, index);
    const fieldError = getFieldError("propertyType", collectionName, index, errors);

    const fieldId = (name) => {
        return getFieldId(name, collectionName, index);
    }

    return (
        <>
            <div className="w-100 d-flex flex-wrap">
                <label htmlFor="propertyType" className="w-100 d-flex justify-content-start align-items-center mb-2">Property Type <small className="d-inline-block text-danger ms-1">(Required)</small></label>          
                <div className="form-check form-check-inline mt-1 mb-2">
                    <input type="radio" value={1} id={fieldId("house")} defaultChecked={defaultValue == 1}
                        {...register(fieldName, { required: "Choose a property type" })}
                        className={`form-check-input ${fieldError ? "is-invalid" : ""}`}
                    />
                    <label className="form-check-label d-block pe-4" htmlFor={fieldId("house")}>House</label>
                </div>
                <div className="form-check form-check-inline mt-1 mb-2">
                    <input type="radio" value={2} id={fieldId("flat")} defaultChecked={defaultValue == 2}
                        {...register(fieldName, { required: "Choose a property type" })}
                        className={`form-check-input ${fieldError ? "is-invalid" : ""}`}
                    />
                    <label className="form-check-label d-block pe-4" htmlFor={fieldId("flat")}>Flat</label>
                </div>
                <div className="form-check form-check-inline mt-1 mb-2">
                    <input type="radio" value={3} id={fieldId("cottage")} defaultChecked={defaultValue == 3}
                        {...register(fieldName, { required: "Choose a property type" })}
                        className={`form-check-input ${fieldError ? "is-invalid" : ""}`}
                    />
                    <label className="form-check-label d-block pe-4" htmlFor={fieldId("cottage")}>Cottage</label>
                </div>
                <ErrorMessage errors={errors} name={fieldName} />
            </div>
        </>
    );
}

export default PropertyTypeSelector;