import React from 'react';
import { useFormContext } from "react-hook-form";
import ErrorMessage from '../../layout/forms/error-message';

interface IProps {
    defaultValue: any;
}

const Notes: React.FC<IProps> = ({ defaultValue }) => {
    const { register, formState: { errors } } = useFormContext();

    return (
        <>
            <label htmlFor="notes" className="w-100 d-flex justify-content-start align-items-center mb-2">Notes <small className="d-inline-block ms-1">(Optional)</small></label>
            <textarea aria-label="Notes" rows={5} aria-describedby="notes"
                placeholder="If applicable, please give us any additional information that may be relevant to your application" maxLength={4000} id="notes" defaultValue={defaultValue}
                {...register("notes", { maxLength: 4000 })}
                className="form-control"
            ></textarea>
        </>
    );
}

export default Notes;