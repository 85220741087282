export const joinNotEmpty = (separator: string, values: string[]): string => {
    let filteredValues: string[] = [];

    if (values) {
        for (let i = 0; i < values.length; i++) {
            if (!isNullOrWhiteSpace(values[i])) {
                filteredValues.push(values[i]);
            }
        }
    }

    //TODO: return null if all are empty?
    return filteredValues.join(separator || "");
}

export const isNullOrWhiteSpace = (value: string): boolean => {
    return !value || value === '' || !value.trim();
}

export const getNumberOrNull = (value: string): number | null => {
    return !isNaN(parseInt(value)) ? parseInt(value) : null;
}