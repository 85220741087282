import axios, { AxiosInstance } from "axios";
import { cacheAdapterEnhancer } from 'axios-extensions';
import { toast } from "react-toastify";

const errorResponse = {
    data: null,
    status: {
      code: 400,
      message: "Error",
      subCode: 0,
    },
    isSucceed: false
}

export const getAPIDefinition = (baseUrl: string): AxiosInstance => {
    //TODO: can we add response interceptor here?
    return axios.create({
        baseURL: baseUrl,
        responseType: "json",
        // disable the default cache and set the cache flag
        adapter: cacheAdapterEnhancer(axios.defaults.adapter!, { enabledByDefault: false, cacheFlag: 'useCache' })
    });
}

export const succeedResponseHandler = (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}

export const failedResponseHandler = (error) => {
    toast.error(error, {
        position: toast.POSITION.TOP_RIGHT
    });
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
}

export const doGetJson = async (url: string, init?) => {
    return await fetch(url, init)
        .then((response) => {
            if (response.status >= 400 && response.status < 600) {
                console.log("Bad response from server - " + response.status);
            }
            return response.json();
        })
        .catch((error) => {
            console.log(error);
            return errorResponse;
        });
}