import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import HeaderBlock from '../../layout/blocks/header-block';
import MainLayout from '../../layout/main-layout';
import ProposalWizard from '../../proposal/wizard';

import { HeaderTypes } from '../../../enums/HeaderTypes';
import { ProposalTypes } from '../../../enums/ProposalTypes';
import { initGA } from "../../../helpers/analyticsHelper";
import { GetFullUrl } from 'src/helpers/urlHelper';

type Props = {
    isGaTracking: boolean;
}

const ProposalBusinessSolePage: React.FC<Props> = ({ isGaTracking }) => {
    useEffect(() => {
        // @ts-ignore
        if (isGaTracking && !window.GA_INITIALIZED) {
            initGA();
            // @ts-ignore
            window.GA_INITIALIZED = true;
        }
    }, [isGaTracking]);

    useEffect(() => {
        if (window && window.dataLayer) {
            window.dataLayer.push({
                'event': 'pageview',
                'pageTitle': window.document.title || '',
                'url': GetFullUrl(),
                'pageCategory': 'businessSoleProp',
            });
        }
    }, []);

    return (
        <MainLayout>
            <Helmet>
                <title>Proposal Sole Trader Application</title>
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"/>
                <meta name="robots" content="noindex,nofollow" />
                {isGaTracking &&
                    <script id="tag-manager" type="application/javascript">
                        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-PG5CHN');`}
                    </script>
                }
            </Helmet>

            <section className="container-fluid py-3 py-md-5 bg-light-grey">
                <div className="container">
                    <div className="row">
                        <div className="col-24 mt-5">
                            <HeaderBlock primaryTitle="Your Finance Application" type={HeaderTypes.h1} variant="mb-3 mb-md-5" />
                        </div>
                    </div>
                    <ProposalWizard proposalType={ProposalTypes.BusinessSole} />
                </div>
            </section>
        </MainLayout>
    );
}

export const mapStateToProps = (state) => {
    return {
        isGaTracking: state.isGaTracking,
    };
}

export default connect(mapStateToProps)(ProposalBusinessSolePage);