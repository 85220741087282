import React, { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import styles from './initial-step.module.scss';
import ErrorMessage from '../../../layout/forms/error-message';

import { logLabelEvent } from '../../../../helpers/analyticsHelper';
import { errorMessagesToString } from '../../../../helpers/validation';
import { GetProposalTypeLabel, GetProposalStepLabel } from '../../../../helpers/enumsHelper';
import { ProposalTypes } from '../../../../enums/ProposalTypes';
import { ProposalFirstStepProps } from '../../types/ProposalFirstStepProps';

const InitialStep: React.FC<ProposalFirstStepProps> = ({ id, variant, stepNumber, formData, onForward }) => {
    const { register, handleSubmit, formState, formState: { errors } } = useForm();

    const submitCallback = (data) => {
        if (onForward) {
            onForward(stepNumber, data);
            logLabelEvent(('prop_form_tracking_' + GetProposalTypeLabel(id)), 'Click', GetProposalStepLabel(id, stepNumber));
        }
    }

    useEffect(() => {
        if (formState.isSubmitted && !formState.isValid) {
            if(Object.keys(errors).length) {
                logLabelEvent(('prop_form_tracking_' + GetProposalTypeLabel(id)), ('step-' + stepNumber), errorMessagesToString(errors));
            }  
        }
    }, [formState.submitCount]);

    return (
        <>
            <h2 className="w-100 d-block h3 mb-3">Takes Approximately 5 Minutes</h2>
            <p className="mb-4 mb-lg-4">To succesfully complete an application you will need the following information at hand:</p>
            <h3 className="h4 mb-3">Information Needed</h3>
            <ul className="ps-0 mb-5">
                {
                    id === ProposalTypes.Personal &&
                    <>
                        <li className="d-flex align-items-center mb-2"><span className={styles["circled-check"]} ><FontAwesomeIcon width="10" height="10" icon={faCheck} /></span> Personal Details</li>
                        <li className="d-flex align-items-center mb-2"><span className={styles["circled-check"]} ><FontAwesomeIcon width="10" height="10" icon={faCheck} /></span> Income / Expenditure</li>
                        <li className="d-flex align-items-center mb-2"><span className={styles["circled-check"]} ><FontAwesomeIcon width="10" height="10" icon={faCheck} /></span> Employment Details</li>
                        <li className="d-flex align-items-center mb-2"><span className={styles["circled-check"]} ><FontAwesomeIcon width="10" height="10" icon={faCheck} /></span> Bank Details</li>
                    </>
                }

                {
                    id === ProposalTypes.BusinessLtd &&
                    <>
                        <li className="d-flex align-items-center mb-2"><span className={styles["circled-check"]} ><FontAwesomeIcon width="10" height="10" icon={faCheck} /></span> VAT &amp; Company Reg No.</li>
                        <li className="d-flex align-items-center mb-2"><span className={styles["circled-check"]} ><FontAwesomeIcon width="10" height="10" icon={faCheck} /></span> Business Details</li>
                        <li className="d-flex align-items-center mb-2"><span className={styles["circled-check"]} ><FontAwesomeIcon width="10" height="10" icon={faCheck} /></span> Director Details</li>
                        <li className="d-flex align-items-center mb-2"><span className={styles["circled-check"]} ><FontAwesomeIcon width="10" height="10" icon={faCheck} /></span> Bank Details</li>
                    </>
                }

                {
                    id === ProposalTypes.BusinessPartner &&
                    <>
                        <li className="d-flex align-items-center mb-2"><span className={styles["circled-check"]} ><FontAwesomeIcon width="10" height="10" icon={faCheck} /></span> VAT &amp; Company Reg No.</li>
                        <li className="d-flex align-items-center mb-2"><span className={styles["circled-check"]} ><FontAwesomeIcon width="10" height="10" icon={faCheck} /></span> Business Details</li>
                        <li className="d-flex align-items-center mb-2"><span className={styles["circled-check"]} ><FontAwesomeIcon width="10" height="10" icon={faCheck} /></span> Partner Details</li>
                        <li className="d-flex align-items-center mb-2"><span className={styles["circled-check"]} ><FontAwesomeIcon width="10" height="10" icon={faCheck} /></span> Bank Details</li>
                    </>
                }

                {
                    id === ProposalTypes.BusinessSole &&
                    <>
                        <li className="d-flex align-items-center mb-2"><span className={styles["circled-check"]} ><FontAwesomeIcon width="10" height="10" icon={faCheck} /></span> VAT Reg No</li>
                        <li className="d-flex align-items-center mb-2"><span className={styles["circled-check"]} ><FontAwesomeIcon width="10" height="10" icon={faCheck} /></span> Business Details</li>
                        <li className="d-flex align-items-center mb-2"><span className={styles["circled-check"]} ><FontAwesomeIcon width="10" height="10" icon={faCheck} /></span> Personal Details</li>
                        <li className="d-flex align-items-center mb-2"><span className={styles["circled-check"]} ><FontAwesomeIcon width="10" height="10" icon={faCheck} /></span> Bank Details</li>
                    </>
                }
            </ul>
            <p className="mb-3">If your application is accepted and you choose to proceed, your funder may conclude your agreement online, using the email address that you have provided and e-sign technology.</p>
            <p className="mb-5 text-warning">Please note if left idle your application may time out and you&apos;ll need to start over again.</p>
            <form id={id + '-' + stepNumber} onSubmit={handleSubmit(submitCallback)}>
                <div className="form-check form-check-inline">
                    <input className={`form-check-input ${errors.agree ? "is-invalid" : ""}`} {...register("agree", { required: "Please agree to sign your finance agreement online" })} type="checkbox" id="agree" defaultChecked={formData.agree} />
                    <label className="form-check-label d-flex justify-content-center align-items-center" htmlFor="agree"><strong>I agree to sign my finance agreement online and receive an executed copy by email</strong> <small className="d-inline-block text-danger ms-1">(Required)</small></label>
                </div>
                <ErrorMessage errors={errors} name="agree" />
                {errors.agree && <span className="w-100 d-block invalid-feedback">.</span>}
                <div className="d-flex flex-wrap justify-content-start mt-5">
                    <button type="submit" className="btn btn-primary btn-block-xs btn-lg bg-gradient">Start Application</button>
                </div>
            </form>
        </>
    );
}

export default InitialStep;