import React from 'react';
import { useFormContext } from "react-hook-form";
import ErrorMessage from '../../layout/forms/error-message';

import { getFieldError, getFieldId, getFieldName } from '../../../helpers/fieldArrayHelper';

interface IProps {
    defaultValue: any;//TODO: change to number? use === comp operator then
    collectionName: string;
    index: number;
}

//TODO: should it register 3 times? check all radiobuttons
const GenderSelector: React.FC<IProps> = ({ defaultValue, collectionName, index }) => {
    const { register, formState: { errors } } = useFormContext();

    const fieldName = getFieldName("gender", collectionName, index);
    const fieldError = getFieldError("gender", collectionName, index, errors);

    const fieldId = (name) => {
        return getFieldId(name, collectionName, index);
    }

    return (
        <>
            <div className="w-100 d-flex flex-wrap">
                <label className="w-100 d-flex justify-content-start align-items-center mb-2">Gender <small className="d-inline-block text-danger ms-1">(Required)</small></label>
                <div className="form-check form-check-inline mt-1 mb-2">
                    <input type="radio" value={1} id={fieldId("male")} defaultChecked={defaultValue == 1}
                        {...register(fieldName, { required: "Choose a gender" })}
                        className={`form-check-input ${fieldError ? "is-invalid" : ""}`}
                    />
                    <label className="form-check-label d-block pe-4" htmlFor={fieldId("male")} >Male</label>
                </div>
                <div className="form-check form-check-inline mt-1 mb-2">
                    <input type="radio" value={2} id={fieldId("female")} defaultChecked={defaultValue == 2}
                        {...register(fieldName, { required: "Choose a gender" })}
                        className={`form-check-input ${fieldError ? "is-invalid" : ""}`}
                    />
                    <label className="form-check-label d-block pe-4" htmlFor={fieldId("female")}>Female</label>
                </div>
                <div className="form-check form-check-inline mt-1 mb-2">
                    <input type="radio" value={3} id={fieldId("other")} defaultChecked={defaultValue == 3}
                        {...register(fieldName, { required: "Choose a gender" })}
                        className={`form-check-input ${fieldError ? "is-invalid" : ""}`}
                    />
                    <label className="form-check-label d-block pe-4" htmlFor={fieldId("other")}>Other</label>
                </div>
                <ErrorMessage errors={errors} name={fieldName} />
            </div>
        </>
    );
}

export default GenderSelector;