import React from 'react';
import { connect } from 'react-redux';
import { useFormContext } from "react-hook-form";
import ErrorMessage from '../../layout/forms/error-message';

import { PhoneNumberIsValid } from '../../../helpers/validation';
import { getFieldError, getFieldId, getFieldName } from '../../../helpers/fieldArrayHelper';

interface IProps {
    isInternal: boolean;
    defaultValue: any;
    label: string;
    collectionName: string;
    index: number;
}

const LandlineNumber: React.FC<IProps> = ({ isInternal, defaultValue, label, collectionName, index }) => {
    const { register, formState: { errors } } = useFormContext();

    const fieldId = getFieldId("landline", collectionName, index);
    const fieldName = getFieldName("landline", collectionName, index);
    const fieldError = getFieldError("landline", collectionName, index, errors);

    return (
        <>
            <label htmlFor={fieldId} className="w-100 d-block mb-2">{label ? label : "Landline Telephone"} <small className="d-inline-block text-danger ms-1">(Required)</small></label>
            <input type="tel" inputMode="tel" aria-describedby={fieldId} placeholder="Landline Telephone" maxLength={20} id={fieldId} defaultValue={defaultValue}
                {...register(fieldName,
                    {
                        required: "Enter a landline number",
                        maxLength: 20,
                        validate: async value => await PhoneNumberIsValid(value, isInternal) === true || 'Valid UK landline number required'
                    }
                )}
                className={`form-control ${fieldError ? "is-invalid" : ""}`}
            />
            <ErrorMessage errors={errors} name={fieldName} />
            <p className="form-text mt-1 mb-0">Example format: (0161 703 8744)</p>
        </>
    );
}

export const mapStateToProps = (state) => {
    return {
        isInternal: state.isInternal,
    };
}

export default connect(mapStateToProps)(LandlineNumber);