import { failedResponseHandler, getAPIDefinition, succeedResponseHandler } from './helpers/apiHelper';

const dataLookupsAPIDefinition = getAPIDefinition(process.env.REACT_APP_GATEWAY_URL);
dataLookupsAPIDefinition.interceptors.response.use(succeedResponseHandler, failedResponseHandler);

export const API_LookupPostcode = (postcode: string) => {
    return dataLookupsAPIDefinition.get(`/lookup/postcodes/${postcode}`);
}

export const API_LookupSortcode = (sortcode: string) => {
    return dataLookupsAPIDefinition.get(`/lookup/sortcodes/${sortcode}`);
}

export const API_ValidatePhoneNumber = async (phoneNumber: string) => {
    let formattedNumber = phoneNumber.replace('+44', '0').replace(/\s+/g, '');
    return dataLookupsAPIDefinition.get(`/comms/validation/phonenumber/${formattedNumber}`);
}