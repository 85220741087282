import React from 'react';
import WizardStep from '../wizard-step';
import BusinessContactDetails from '../business/business-contact-details';

import { ProposalTypes } from '../../../enums/ProposalTypes';
import { ProposalStepProps } from '../types/ProposalStepProps';

const BusinessContactsStep: React.FC<ProposalStepProps> = ({ id, stepNumber, formData, onBack, onForward }) => {
    return (
        <WizardStep id={id} stepNumber={stepNumber} onBack={onBack} onForward={onForward}>
            <BusinessContactDetails formData={formData} includeMobileNumber={id === ProposalTypes.BusinessSole} />
        </WizardStep>
    );
}

export default BusinessContactsStep;