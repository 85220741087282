import React, { useState, useEffect } from 'react';
import { nanoid } from 'nanoid';
import classnames from 'classnames';
import PopOver from '../../layout/forms/popover';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import styles from './indicator.module.scss';

interface IProps {
    id: number;
    steps: number;
    currentStep: number;
    formData: any;
    handleFormStep: any;
    handleProgressReferer: any;
}

const Indicator: React.FC<IProps> = ({ id, steps, currentStep, formData, handleFormStep, handleProgressReferer }) => {
    const progress = (100 / (steps - 1)) * (currentStep - 1);

    //TODO: do we need this state?
    const [indicatorHtml, setIndicatorHtml] = useState(null);

    useEffect(() => {
        setIndicatorHtml(generateIndicator(currentStep, formData));
    }, [currentStep, formData]);

    function generateIndicator(currentStepIdx, data) {
        const dynamicSteps = new Array(steps).fill(null);

        let mapHtml = dynamicSteps.map((step, index) => {
            let stepIdx = index + 1;
            let prevStep = index;
            let stepIsPopulated = currentStepIdx && currentStepIdx < steps ? Object.keys(data[currentStepIdx])?.length : 0;
            let prevIsPopulated = index > 0 ? Object.keys(data[prevStep])?.length : 0;
            let currentIsPopulated = stepIdx ? Object.keys(data[stepIdx])?.length : 0;

            if (currentStepIdx === stepIdx) {
                // Current step
                return <span key={nanoid()} className={classnames([styles['circle']], [styles['is-current']])}>{currentIsPopulated ? <FontAwesomeIcon width="10" height="10" icon={faCheck} /> : stepIdx}</span>;
            } else if (stepIdx < currentStepIdx) {
                // Previous step
                let stepButton =
                    stepIsPopulated ?
                        <button key={nanoid()} type="submit" form={id + '-' + currentStepIdx} className={classnames([styles['circle']], [styles['is-clickable']], [styles['is-active']])} onClick={() => handleProgressReferer(stepIdx)}>
                            <FontAwesomeIcon width="10" height="10" icon={faCheck} />
                        </button>
                        :
                        <span key={nanoid()} className={classnames([styles['circle']], [styles['is-clickable']], [styles['is-active']])} onClick={() => handleFormStep(stepIdx)}>{currentIsPopulated ? <FontAwesomeIcon width="10" height="10" icon={faCheck} /> : stepIdx}</span>
                    ;
                return <PopOver key={nanoid()} id={`step-${stepIdx}-popover`} type={['hover', 'focus']} trigger={stepButton} body={`Edit step number ${stepIdx}`} />;
            } else if (stepIdx > currentStepIdx) {
                // Next step
                return prevIsPopulated ?
                    <PopOver key={nanoid()} id={`step-${stepIdx}-popover`} type={['hover', 'focus']} trigger={<button type="submit" form={id + '-' + currentStepIdx} className={classnames([styles['circle']], [styles['is-clickable']])} onClick={() => handleProgressReferer(stepIdx)}>{currentIsPopulated ? <FontAwesomeIcon width="10" height="10" icon={faCheck} /> : stepIdx}</button>} body={`Start step number ${stepIdx}`} />
                    :
                    <span key={nanoid()} className={classnames([styles['circle']])}>{stepIdx}</span>
                    ;
            } else {
                // Next step          
                return <span key={nanoid()} className={classnames([styles['circle']])}>{stepIdx}</span>
            }
        })

        return mapHtml;
    }

    return (
        <div id="progress-indicator" className={classnames([styles['indicator'], 'mb-5'])}>
            <div style={{ width: progress + '%' }} className={classnames([styles['progress']])}></div>
            {indicatorHtml}
        </div>
    );
}

export default Indicator;