import React from 'react';
import { connect } from 'react-redux';
import { useFormContext } from "react-hook-form";
import EmailAddress from '../common/email-address';
import MobileNumber from '../common/mobile-number';
import FullName from '../common/full-name';
import ErrorMessage from '../../layout/forms/error-message';

import { PhoneNumberIsValid } from '../../../helpers/validation';

interface IProps {
    isInternal: boolean;
    formData: any;
}

//TODO: Single error message for required + validate rules?
const PersonalContactDetails: React.FC<IProps> = ({ isInternal, formData }) => {
    const { register, formState: { errors } } = useFormContext();

    //TODO: accept as component params
    const queryParams = new URLSearchParams(window.location.search);
    const emailDefaultValue = queryParams.get("email") ? queryParams.get("email") : formData.emailAddress;
    const isPrepopulated = queryParams.get("email") !== null ? true : false;

    return (
        <>
            <div className="row">
                <div className="col-24">
                    <h2 className="w-100 d-block h3 mb-3 mb-md-4 mb-lg-5">Your Details</h2>
                </div>
            </div>
            <FullName formData={formData} collectionName={null} index={0} />
            <div className="row">
                <div className="col-24 col-md-8 mb-3 mb-md-0">
                    <EmailAddress defaultValue={emailDefaultValue} readOnly={isPrepopulated} />
                </div>
                <div className="col-24 col-md-8 mb-3 mb-md-0">
                    <MobileNumber defaultValue={formData.mobileNumber} />
                </div>
                <div className="col-24 col-md-8">
                    <div className="d-flex flex-wrap">
                        <label htmlFor="homeNumber" className="w-100 d-flex justify-content-start align-items-center mb-2">Home Number <small className="d-inline-block ms-1">(Optional)</small></label>
                        <input type="tel" inputMode="tel" aria-describedby="homeNumber" placeholder="Home Number" maxLength={20} id="homeNumber" defaultValue={formData.homeNumber}
                            {...register("homeNumber",
                                {
                                    required: false,
                                    maxLength: 20,
                                    validate: async value => value === '' || await PhoneNumberIsValid(value, isInternal) === true || 'Valid UK landline number required'
                                }
                            )}
                            className={`form-control ${errors.homeNumber ? "is-invalid" : ""}`}
                        />
                        <ErrorMessage errors={errors} name="homeNumber" />
                        <p className="form-text mt-1 mb-0">Example format: (0161 703 8744)</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export const mapStateToProps = (state) => {
    return {
        isInternal: state.isInternal,
    };
}

export default connect(mapStateToProps)(PersonalContactDetails);