import React, { useState, useEffect } from 'react';
import WizardStep from './wizard-step';
import Indicator from './indicator';
import BusinessPerson from './business/business-person';
import BankDetailsStep from './common-steps/bank-details-step';
import BusinessDetailsStep from './common-steps/business-details-step';
import BusinessContatcsStep from './common-steps/business-contacts-step';
import InitialStep from './common-steps/initial-step/initial-step';
import CompleteStep from './common-steps/complete-step';

import { ProposalTypes } from '../../enums/ProposalTypes';
import { IWizardStepsProps } from './types/IWizardStepsProps';

type animations = {
    back: string;
    forward: string;
}

const BusinessSoleSteps: React.FC<IWizardStepsProps> = ({ step, formData, onBack, onForward, onStepChange, onProgressRefererChange, onSubmit }) => {
    const queryParams = new URLSearchParams(window.location.search);
    const isDebugging = queryParams.get("debug") ? true : false;
    const debugAnimations = {
        back: "",
        forward: ""
    }
    const panelAnimations = {
        back: "slide-up",
        forward: "slide-out"
    }  
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [prefersReducedMotion, setPrefersReducedMotion] = useState<boolean>(false);
    const [currentAnimations, setCurrentAnimations] = useState<animations>((isDebugging || prefersReducedMotion) ? debugAnimations : panelAnimations);

    const handleStepBack = (step: number) => {
        if (!prefersReducedMotion) {
            setIsLoading(true);
        }

        const stepChange = setTimeout(() => {
            onBack(step);
            setIsLoading(false);
            clearTimeout(stepChange);
        }, 300);
        
    }

    const handleStepForward = (step: number, data) => {
        if (!prefersReducedMotion) {
            setIsLoading(true);
        }

        const stepChange = setTimeout(() => {
        onForward(step, data);
            setIsLoading(false);
            clearTimeout(stepChange);
        }, 300);
        
    }

    useEffect(() => {
        if(!isDebugging) {
            window.scrollTo(0, 0);
        }
    }, [isLoading]);

    useEffect(() => {
        setCurrentAnimations((isDebugging || prefersReducedMotion) ? debugAnimations : panelAnimations);
    }, [prefersReducedMotion])

    useEffect(() => {
        // Update motion preference
        const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
        setPrefersReducedMotion(mediaQuery.matches);
        
        mediaQuery.addEventListener('change', () => {
            setPrefersReducedMotion(mediaQuery.matches);
        });

        return () => {
            mediaQuery.removeEventListener('change', () => {
                setPrefersReducedMotion(mediaQuery.matches);
            });
        }
    }, []);

    return (
        <>
            <div className="row">
                <div className="col-24 z-xs">
                    <Indicator id={ProposalTypes.BusinessSole} steps={6} currentStep={step} formData={formData} handleFormStep={onStepChange} handleProgressReferer={onProgressRefererChange} />
                </div>
            </div>
            <div className="row">
                <div className="col-24 z-sm">
                    <div className={`w-100 bg-white shadow border-light rounded-3 p-3 p-md-5 ${isLoading ? currentAnimations.forward : currentAnimations.back}`}>
                        {(step === 1 || isDebugging) && <InitialStep id={ProposalTypes.BusinessSole} stepNumber={1} formData={formData[1]} onForward={handleStepForward} />}
                        {(step === 2 || isDebugging) && <BusinessDetailsStep id={ProposalTypes.BusinessSole} stepNumber={2} formData={formData[2]} onForward={handleStepForward} onBack={handleStepBack} />}
                        {(step === 3 || isDebugging) && <BusinessContatcsStep id={ProposalTypes.BusinessSole} stepNumber={3} formData={formData[3]} onForward={handleStepForward} onBack={handleStepBack} />}
                        {
                            (step === 4 || isDebugging) &&
                            <WizardStep id={ProposalTypes.BusinessSole} stepNumber={4} onBack={handleStepBack} onForward={handleStepForward}>
                                <div className="row">
                                    <div className="col-24">
                                        <h2 className="w-100 d-block h3 mb-3 mb-md-4 mb-lg-5">Personal Details</h2>
                                    </div>
                                </div>
                                <BusinessPerson formData={formData[4]} />
                            </WizardStep>
                        }
                        {(step === 5 || isDebugging) && <BankDetailsStep id={ProposalTypes.BusinessSole} stepNumber={5} formData={formData[5]} onForward={handleStepForward} onBack={handleStepBack} />}
                        {(step === 6 || isDebugging) && <CompleteStep id={ProposalTypes.BusinessSole} stepNumber={6} formData={formData[6]} onBack={handleStepBack} onSubmit={onSubmit} />}
                    </div>
                </div>
            </div>
        </>
    );
}

export default BusinessSoleSteps;